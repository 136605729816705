import { currency } from 'app/helpers';
import React, { useEffect, useRef, useState } from 'react';
import { Spinner, Table } from 'react-bootstrap';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import { BET_TYPES } from 'app/modules/luckyNumber/constants';
import { getPkGameHistory, storePkGameCancel } from '../redux/pkGameApi';
import { History } from 'app/modules/luckyNumber/models/History';
import { PKGameOrderInfo } from './PKGameOrderInfo';
import { OrderInfoModel } from '../models/OrderInfoModel';

export function PKGameHistory() {
  const [personal, setPersonal] = useState(false)
  const [modalOrderInfo, setModalOrderInfo] = useState(false)
  const [orderInfo, setOrderInfo] = useState<OrderInfoModel[]>([])
  const [limit, setLimit] = useState(10)
  const [histories, setHistory] = useState<History[]>([])
  const spinner = <Spinner animation="border" size="sm" variant="primary" />
  const hisRef = useRef<NodeJS.Timeout>()

  useEffect(() => {
    handleHistory()
  }, [limit, personal])

  useEffect(() => {
    if(hisRef.current) clearInterval(hisRef.current)

    hisRef.current = setInterval(async () => {
      handleHistory()
    }, 5000)

    return () => {
      if(hisRef.current) clearInterval(hisRef.current)
    }
  }, [limit, personal])

  function handleHistory(){
    getPkGameHistory(limit, personal).then((data: any) => {
      setHistory(data)
    }).finally(() => {

    })
  }

  function showOrder(logs: OrderInfoModel[]){
    setOrderInfo(logs)
    setModalOrderInfo(true)
  }

  function handleCancel(id: number) {
    Swal.fire({
      title: 'Bạn chắc chứ?',
      text: "Bạn có muốn hủy đặt cược không?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Chắc',
      cancelButtonText: 'Hủy'
    }).then((result) => {
      if (result.isConfirmed) {
        storePkGameCancel(id).then((data: any) => {
          handleHistory()
          Swal.fire('Thông báo','Hủy đơn hàng thành công', 'success')
        }).catch((error) => {
          Swal.fire('Thất bại', error.message ?? 'Có lỗi xảy ra', 'error')
        })
      }
    })
  }

  return (
    <>
      <div className="row">
        <PKGameOrderInfo info={orderInfo} display={modalOrderInfo} setDisplay={setModalOrderInfo}/>
        <div className="text-center font-weight-bold w-100 mb-1 mt-2">
          <h4 id="darkMode4" className="text-dark">Lịch sử đặt cược</h4>
        </div>
        <div className="table-responsive bet-history">
          <Table striped bordered className="text-center">
            <thead className="bg-info text-white">
              <tr>
                <th scope="col" className="nowrap">Nhân vật</th>
                <th scope="col" className="nowrap">Tình trạng</th>
                <th scope="col" className="nowrap">Vàng đặt</th>
                <th scope="col" className="nowrap">Dự đoán</th>
                <th scope="col" className="nowrap">Sát thương</th>
                <th scope="col" className="nowrap">Kết quả</th>
                <th scope="col" className="nowrap">Vàng nhận</th>
                <th scope="col" className="nowrap">Thời gian</th>
              </tr>
            </thead>
            <tbody>
                {histories.map((history, key) => {
                  return (key < limit) && (
                    <tr key={key}>
                      <td>{history.user.name}</td>
                      <td>{history.status === 0 ? spinner : <span className={`badge badge-${history.status === 1 ? 'success' : (history.status === 2 ? 'danger' : 'warning')}`}>{history.status === 1 ? 'Thắng' : (history.status === 2 ? 'Thua' : 'Hủy')}</span>}</td>
                      <td>{currency(history.amount)}</td>
                      <td>{BET_TYPES[history.type]}</td>
                      <td>
                        {history.status === 0 && spinner}
                        {(history.status === 1 || history.status === 2) && (history.result)}
                      </td>
                      <td>
                        {history.status === 0 && spinner}
                        {(history.status === 1 || history.status === 2) && (history.result ? history.result.toString().substring(history.result.toString().length - 3).split('').join('+') : '')}
                      </td>
                      <td>{history.amount_won ? currency(history.amount_won) : 0}</td>
                      <td>{(history.status === 0) ? history.created_at.substring(0, 8) : (
                        <span style={{ cursor: 'pointer' }} className="text-primary" onClick={() => showOrder(history.notes)}>
                          <FontAwesomeIcon icon={faEye} />
                        </span>
                      )}</td>
                    </tr>
                  )
                  }
                )}
            </tbody>
          </Table>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-2 col text-dark">
          <label>Hiện thị:</label>
          <select id="isMe" className="form-control" onChange={(e) => setPersonal(e.target.value === '1' ? true : false)}>
            <option value="0">Tất cả</option>
            <option value="1">Mình tôi</option>
          </select>
        </div>
        <div className="col offset-lg-8 col text-dark" id="darkMode6">
          <label>Bản ghi:</label>
          <select id="record" className="form-control" onChange={(e) => setLimit(parseInt(e.target.value ?? '10'))}>
            <option value="10">10 bản ghi</option>
            <option value="20">20 bản ghi</option>
            <option value="30">30 bản ghi</option>
            <option value="50">50 bản ghi</option>
            <option value="100">100 bản ghi</option>
          </select>
        </div>
      </div>
    </>
  )
}
