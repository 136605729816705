import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginationParams } from "app/modules/luckyNumber/models/PaginationParams";
import { History } from "app/modules/payment/models/History"
import { ICharging } from "../models/Charging";
import { ITransaction } from "../models/Transaction";
import { WalletInfo } from "../models/WalletInfo";

export interface paymentState {
	counter: number;
	loading: boolean;
	histories: PaginationParams<History>;
	transactions: PaginationParams<ITransaction>;
	chargings: PaginationParams<ICharging>;
	goldBarPrice: number;
	chargingRate: number[];
	walletInfo: WalletInfo
}

const INITIAL_STATE: paymentState = {
	loading: false,
	counter: 0,
	goldBarPrice: 0,
	histories: { 
		data: [],
		current_page: 1,
		per_page: 10,
		total: 0,
		last_page: 0,
	},
	transactions: {
		data: [],
		current_page: 1,
		per_page: 10,
		total: 0,
		last_page: 0,
	},
	chargings: {
		data: [],
		current_page: 1,
		per_page: 10,
		total: 0,
		last_page: 0,
	},
	chargingRate: [],
	walletInfo: {
		name: '',
		account: '',
		account_number: '',
		account_name: '',
		rate: [],
		syntax: 'NAP '
	}
};

const paymentSlice = createSlice({
	name: "payment",
	initialState: INITIAL_STATE,
	reducers: {
		setLoading(state, action: PayloadAction<boolean>) { 
			state.loading = action.payload
		},
		setHistory(state, action: PayloadAction<any>) { 
			state.histories = action.payload
		},
		setTransaction(state, action: PayloadAction<any>) { 
			state.transactions = action.payload
		},
		setChargingHistory(state, action: PayloadAction<any>) { 
			state.chargings = action.payload
		},
		setGoldBarPrice(state, action: PayloadAction<any>) { 
			state.goldBarPrice = action.payload
		},
		setChargingRate(state, action: PayloadAction<number[]>) { 
			state.chargingRate = action.payload
		},
		setWalletInfo(state, action: PayloadAction<WalletInfo>) { 
			state.walletInfo = action.payload
		},
		refreshHistory(state){
			state.counter++
		}
	},
});

export const paymentActions = paymentSlice.actions;

export const paymentReducer = paymentSlice.reducer;

