import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { currency } from 'app/helpers';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import React, { useEffect, useState } from 'react';
import { Alert, Badge, Tab, Table } from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import Pagination from 'react-js-pagination';
import { Link, useLocation, useParams } from 'react-router-dom';
import { SideBar } from '../auth/components/SideBar';
import { WalletInfo } from './models/WalletInfo';
import { fetchWalletHistory, fetchWalletInfo } from './redux/paymentApi';
import { paymentActions } from './redux/paymentSlice';
import { toast } from 'react-toastify';
import { VipPopup } from './components/VipPopup';

export function Wallet() {
  const [vipPopup, setVipPopup] = useState(true)
  const dispatch = useAppDispatch()
  const location = useParams<any>();
  const { walletInfo } = useAppSelector(({ payment }) => ({ walletInfo: payment.walletInfo }))
  const { currentUser } = useAppSelector(({ auth }) => ({ currentUser: auth.currentUser }))
  const payment = useAppSelector(({ payment }) => payment)
  const [info, setInfo] = useState<WalletInfo>()
  const [content, setContent] = useState('')
  const spinner = (<FontAwesomeIcon icon={faSpinner} size="lg" spin />);
  const { loading } = useAppSelector(({ payment, auth }) => ({
    loading: payment.loading,
  }))


  useEffect(() => {
    dispatch(paymentActions.setLoading(true))
    fetchWalletInfo(location.type).then((info: any) => {
      dispatch(paymentActions.setWalletInfo(info))
      setInfo(info)
      setContent(`${info.syntax ?? ''} ${currentUser?.id}`)
    }).finally(() => {
      dispatch(paymentActions.setLoading(false))
    })

    reloadHistory(payment.histories.current_page)
  }, [location.type])

  function reloadHistory(page: number) {
    dispatch(paymentActions.setLoading(true))
    fetchWalletHistory({ page: page, type: location.type }).then((data) => {
      dispatch(paymentActions.setHistory(data))
    }).finally(() => {
      dispatch(paymentActions.setLoading(false))
    })
  }

  return (
    <>
      <VipPopup display={vipPopup} setDisplay={setVipPopup}></VipPopup>
      <div className="row py-5">
        <SideBar />
        <div className="col-lg-9">
          <h4 className="title-text">Nạp ví</h4>
          <hr className="title-line" />

          <Alert variant="info">
            <span className="text-danger font-weight-bold">Tỉ lệ nạp ví {walletInfo.rate[currentUser?.server_id ?? 1] ?? 0}%</span>
            <p>
              Nạp <b>100k</b> được <b>{currency((walletInfo.rate[currentUser?.server_id ?? 1] ?? 0) * 100000)}</b><br />
              Cứ <b>100k</b> bạn sẽ nhận được <b>10 kim cương với</b> tương đương <b>1 lượt quay</b><br />
              Kim cương <strong>đổi lượt quay</strong> vàng <Link to="/wheel" style={{ fontSize: '16px', color: 'rgb(5, 67, 191)' }}><b>tại đây</b></Link><br />
              {/* <LinkDirection setType={setType}></LinkDirection> */}
            </p>
          </Alert>

          <div className="form-group row">
            <div className="col-lg-1"></div>
            <label className="col-form-label col-lg-2">Tài khoản: </label>
            <div className="col-lg-8">
              <input className="form-control" type="text" defaultValue={`${currentUser?.name} - Vũ Trụ ${currentUser?.server_id}`} readOnly />
            </div>
          </div>
          {location.type === 3 && (
            <div className="form-group row">
              <div className="col-lg-1"></div>
              <label className="col-form-label col-lg-2">Ngân hàng: </label>
              <div className="col-lg-8">
                <input className="form-control" type="text" value={loading ? 'Đang tải...' : info?.name} readOnly />
              </div>
            </div>
          )}

          <div className="form-group row">
            <div className="col-lg-1"></div>
            <label className="col-form-label col-lg-2">Chủ tài khoản: </label>
            <div className="col-lg-8">
              <input className="form-control" type="text" value={loading ? 'Đang tải...' : info?.account_name} readOnly />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-1"></div>
            <label className="col-form-label col-lg-2">Số tài khoản: </label>
            <div className="col-lg-8">
              <div className="input-group">
                <input className="form-control text-danger font-weight-bold" type="text" value={loading ? 'Đang tải...' : info?.account_number} readOnly />
                <div className="input-group-append">
                  <CopyToClipboard text={info?.account_number ?? ''} onCopy={() => toast('Sao chép thành công')}>
                    <button className="btn btn-outline-secondary" type="button">Copy</button>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-1"></div>
            <label className="col-form-label col-lg-2">Nội dung chuyển tiền: </label>
            <div className="col-lg-8">

              <div className="input-group">
                <input className="form-control text-danger font-weight-bold" type="text" value={loading ? 'Đang tải...' : `${info?.syntax ?? ''} ${currentUser?.id}`} readOnly />
                <div className="input-group-append">
                  <CopyToClipboard text={`${info?.syntax ?? ''} ${currentUser?.id}` ?? ''} onCopy={() => toast('Sao chép thành công')}>
                    <button className="btn btn-outline-secondary" type="button">Copy</button>
                  </CopyToClipboard>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
      <div className="row">
        <div className="table-responsive">
          <Table bordered hover>
            <thead className="bg-info text-white">
              <tr>
                <th>Thời gian</th>
                <th>Tình trạng</th>
                <th>Số tiền</th>
                <th>Thực nhận</th>
              </tr>
            </thead>
            <tbody>
              {payment.histories.data.length === 0 && !payment.loading ? (<tr className="text-center"><td colSpan={6}>Không có dữ liệu</td></tr>) : null}
              {payment.loading ? (<tr className="text-center"><td colSpan={6}>Đang tải...</td></tr>) :
                payment.histories.data.map((history, key) => (
                  <tr key={key}>
                    <td>{history.created_at}</td>
                    <td><Badge bg="success" color="white">Thành công</Badge></td>
                    <td>{currency(history.amount)}</td>
                    <td>{currency(history.received)}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <Pagination
            activePage={payment.histories.current_page}
            itemsCountPerPage={payment.histories.per_page}
            totalItemsCount={payment.histories.total}
            pageRangeDisplayed={5}
            onChange={(page: number) => reloadHistory(page)}
            itemClass="page-item"
            linkClass="page-link"
          />
        </div>
      </div>
    </>
  )
}
