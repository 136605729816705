import React, {useEffect} from 'react'
import {useLocation} from 'react-router'
import { useLayout } from '.'

const Content: React.FC = ({children}) => {
  const { setNavMenu } = useLayout()
  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0);
    setNavMenu(false)
  }, [location])

  return (
    <div id='content_container'>
      {children}
    </div>
  )
}

export {Content}
