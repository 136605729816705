import { faHistory, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RollDiceCommand from 'app/commands/RollDiceCommand';
import { useSocket } from 'app/components/core/Socket';
import { SOCKET_CMD } from 'app/contants';
import { currency } from 'app/helpers'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { BET_TYPES } from 'app/modules/luckyNumber/constants';
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { diceActions } from '../redux/diceSlice';

interface DiceHistoryProps {
  display: boolean
  setDisplay: (value: boolean) => void
}

export function DiceHistory() {
  const dispatch = useAppDispatch()
  const { socket } = useSocket()
  const spinner = (<FontAwesomeIcon icon={faSpinner} size="1x" spin />)
  const dice = useAppSelector(({ dice }) => dice)
  const [pageNumber, setPageNumber] = useState(1)
  const [postNumber] = useState(12)
  const currentPageNumber = (pageNumber * postNumber) - postNumber
  const paginatedHistories = dice.histories.slice().splice(currentPageNumber, postNumber)
  const [personal, setPersonal] = useState(false)

  const handlePrev = () => {
    if (pageNumber === 1) return
    setPageNumber(pageNumber - 1)
  }
  const handleNext = () => {
    setPageNumber(pageNumber + 1)
  }

  useEffect(() => {
    if (personal) {
      socket.emit(RollDiceCommand.DICE_USER_HISTORY, {server: 1})
    }else{
      socket.emit(RollDiceCommand.DICE_HISTORY_PULL, {server: 1})
    }
  }, [personal])

  useEffect(() => {
    socket.on(RollDiceCommand.DICE_USER_HISTORY, (data) => {
      if(data.server !== 1) return
      dispatch(diceActions.setHistory(data.histories))
    })
    return () => { socket.off(RollDiceCommand.DICE_USER_HISTORY) }
  }, [socket])

  return (
      <>
        <table className="table table-sm text-center mt-0">
          <tbody>
            <tr className="bg bg-danger text-white">
              <th>Người chơi</th>
              <th>Cửa đặt</th>
              <th>Tiền cược</th>
              <th>Trạng thái</th>
            </tr>
            {paginatedHistories.map((item, key) => (
              <tr key={key}>
                <th>{item.name ?? item.meta.name}</th>
                <th>{BET_TYPES[item.type ?? item.meta.type]}</th>
                <th>{currency(item.amount)}</th>
                {/* <th className={`text-${item.status === 1 ? 'success' : (item.status === 2 && 'danger')}`}>
                  {item.status === 0 ? spinner : (item.amount_won > 0 ? currency(item.amount_won) : 'Thua')}
                </th> */}
                <td>
                  {item.status === 0 ? spinner : (
                  <span className={`badge badge-${item.status === 1 ? 'success' : 'danger'}`}>{item.status === 1 ? '+' + currency(item.amount_won) : (item.status === 2 ? 'Thua' : 'Hủy')}</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="his-nav">
          <div className="d-flex mb-0 pb-0 pl-2">
            <div className="mr-auto">
              <div className="nav-option-show">
                <form method="post" action="/">
                  <label >
                    <input type="checkbox" name="" id="uniqueID" onChange={(e) => setPersonal(e.target.checked)} /> Cá nhân
                  </label>
                </form>
              </div>
            </div>
            <div>
              <nav aria-label="Page navigation example">
                <ul className="pagination mb-0">
                  <li className="page-item">
                    <button onClick={handlePrev} className="page-link">Trước</button>
                  </li>
                  <li className="page-item">
                    <button className="page-link">{(pageNumber)}</button>
                  </li>
                  <li className="page-item">
                    <button onClick={handleNext} className="page-link">Tiếp</button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </>
  )
}


export function DiceHistoryModal({ display, setDisplay }: DiceHistoryProps) {
  
  return (
    <Modal show={display} onHide={() => setDisplay(false)}>
      <Modal.Header closeButton>
        <div className="col text-center">
          <span className="font-weight-bold">Lịch sử</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <DiceHistory/>
      </Modal.Body>
    </Modal >
  )
}

export function DiceHistoryCard(){
  return (
    <div className="card border-info mt-3">
        <div className="card-header bg-info text-light border-seccond text-center font-weight-bold">
            <FontAwesomeIcon icon={faHistory} /> LỊCH SỬ CHƠI
        </div>
        <div className="table-responsive">
            <DiceHistory/>
        </div>
    </div>
  )
}
