import React, { useEffect, useState } from 'react';
import { faGamepad, faCoins, faGavel, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector } from 'app/hooks';
import { currency } from 'app/helpers';
import { Alert, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GameButtonType } from 'app/modules/luckyNumber/components/GameButtonType'
import CurrencyInput from 'react-currency-input-field';
import { useLayout } from 'app/components/layout';
import { storePkGameStore } from '../redux/pkGameApi';

export function PKGameForm() {
  const { response, alertNotice } = useLayout()
  const [currentGame, setCurrentGame] = useState('1')
  const [currentType, setCurrentType] = useState(1)
  const [amount, setAmount] = useState('')
  const [ingame, setIngame] = useState('')
  const { isAuthorized, currentUser } = useAppSelector(({ auth, lucky }) => ({
    isAuthorized: auth.currentUser !== undefined,
    currentUser: auth.currentUser,
  }))

  function handlePlay(e: any) {
    e.preventDefault()
    if (amount === '' || parseInt(amount) < 1000000) {
      return alertNotice('Số tiền đặt cược tối thiểu là 1 triệu vàng')
    }
    
    storePkGameStore({amount: amount, type: currentType, ingame: ingame}).then((data: any) => {
      alertNotice(data.message, 'success')
    }).catch((error) => {
      alertNotice(error.message ?? 'Có lỗi xảy ra')
    }).finally(() => {
      setAmount('')
    })
    
  }

  return (
    <div className="card border-info">
      <div className="card-header bg-info text-light border-seccond text-center font-weight-bold">
        <FontAwesomeIcon icon={faGavel} /> ĐẶT CƯỢC
      </div>
      <div className="card-body border-seccond bg-light text-dark" style={{ padding: "30px 15px" }}>
        <div className="input-group form-group">
          <span className="input-group-prepend">
            <div className="input-group-text bg-light border-right-0 text-warning">
              <FontAwesomeIcon icon={faCoins} />
            </div>
          </span>
          <div className="form-control py-2 border-left-0 border readonly">{currency(currentUser?.balance ?? 0)}</div>
        </div>
        <form onSubmit={handlePlay}>
          <div className="input-group form-group">
            <span className="input-group-prepend">
              <div className="input-group-text bg-light border-right-1" style={{ color: 'red', padding: "6px 10px" }}>
                <FontAwesomeIcon icon={faGamepad} />
              </div>
            </span>
            <select className="form-control py-2 border-left-0 border text-danger" name="type" onChange={(e) => setCurrentGame(e.target.value)}>
              <option value="1">Đỏ Đen - Tài xỉu (10tr ăn 19tr)</option>
            </select>
          </div>

          <label>Kết quả dự đoán: </label>
          <div className="form-group" id="divValue">
            {(currentGame === '1' || currentGame === '2') && (
              <div className="btn-group" style={{ width: "100%" }}>
                <div className="row" style={{ width: "100%" }}>
                  {currentGame === '1' && (
                    <>
                      <GameButtonType name="Đỏ" variant="info" onClick={() => setCurrentType(1)} show={currentType === 1} />
                      <GameButtonType name="Đen" variant="warning" onClick={() => setCurrentType(2)} show={currentType === 2} />
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="form-group">
            <input type="text" name="value" onChange={(e) => setIngame(e.target.value)} placeholder="Nhập tên nhân vật" className="form-control" />
          </div>
          <div className="input-group form-group">
            <span className="input-group-prepend" style={{ borderRight: '1px solid red' }}>
              <div className="input-group-text bg-light border-right-1">
                <FontAwesomeIcon icon={faGavel} />
              </div>
            </span>
            <CurrencyInput value={amount} allowNegativeValue={false} type="search" className="form-control py-2 border-left-0 border font-weight-bold amount-play" placeholder="Nhập số tiền đặt cược" onValueChange={(value) => setAmount(value ?? '')} />
          </div>

          {response.message && <Alert variant={response.type}>{response.message}</Alert>}

          {!isAuthorized ? (
            <Link to="/sign-in" className="btn btn-danger font-weight-bold text-uppercase form-control">
              <FontAwesomeIcon icon={faSignInAlt} /> ĐĂNG NHẬP ĐỂ THỰC HIỆN
            </Link>
          ) :
            <Button type="submit" variant="info" className="font-weight-bold form-control">ĐẶT NGAY</Button>
          }

        </form>
      </div>
    </div>
  )
}
