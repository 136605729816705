import axios from "axios";
import { LoginPayload, PasswordPayload, RegisterPayload, UpdatePayload } from "./authSlice";

export const LOGIN_URL = `/auth/login`;
export const UPDATE_URL = `/auth/verify/name`;
export const ME_URL = `/auth/me`;
export const PASSWORD_URL = `/auth/password`;
export const LOGOUT_URL = `/auth/logout`;
export const REGISTER_URL = `/auth/register`;
export const VIP_URL = `/payment/vip`;

export function login({ username, password }: LoginPayload) {
	return axios.post(LOGIN_URL, { username, password });
}

export function register({ username, email, password }: RegisterPayload) {
	return axios.post(REGISTER_URL, { username, email, password });
}

export function updateProfile({ name, server_id, avatar_id, referral_code }: UpdatePayload) {
	return axios.post(UPDATE_URL, { name, server_id, avatar_id, referral_code });
}

export function changePassword({ currentPassword, newPassword }: PasswordPayload) {
	return axios.put(PASSWORD_URL, { currentPassword, newPassword });
}

export function getUserByToken() {
	return axios.get(ME_URL);
}

export function receiveVipGift() {
	return axios.post(VIP_URL);
}