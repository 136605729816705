import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks';
import logo from '../../../assets/images/logo.png';
import { useLayout } from '.';
import { currency } from 'app/helpers';
import { Link, useLocation } from 'react-router-dom';
import { authActions } from 'app/modules/auth/redux/authSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faSignOutAlt, faUser, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { Nav, Navbar, Button } from 'react-bootstrap';


const Header: React.FC = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const dispatch = useAppDispatch()
  const location = useLocation()

  useEffect(() => {
    setOpenMenu(false)
  }, [location])

  const { isAuthorized, user } = useAppSelector(({ auth }) => ({
    isAuthorized: auth.currentUser !== undefined,
    user: auth.currentUser
  }))

  return (
    <>
      <div className="container-fluid" style={{ padding: '0px' }}>
        <Navbar bg="info" expand="lg">
          <div className="container">
            <Link to="/home">
              <img src={logo} alt="Mini Game Ngọc Rồng Online" style={{ height: '50px' }} />
            </Link>
            <Button
              onClick={() => setOpenMenu(!openMenu)}
              aria-expanded={openMenu}
              className="navbar-toggler"
              variant="info"
            >
              <span className="navbar-toggler-icon"></span>
            </Button>
            <Navbar.Collapse in={openMenu}>
              <Nav className="navbar-nav mr-auto">
                <li className="nav-item font-weight-bold li-item">
                  <Link className="nav-link a-item" to="/home">
                    TRANG CHỦ
                  </Link>
                </li>
                {/* <li className="nav-item font-weight-bold li-item">
                  <Link className="nav-link a-item" to="/charging">
                    MUA VÀNG
                  </Link>
                </li> */}
                <li className="nav-item font-weight-bold li-item">
                  <Link className="nav-link a-item" to="/payment">
                    NẠP VÀNG
                  </Link>
                </li>
                <li className="nav-item font-weight-bold li-item">
                  <Link className="nav-link a-item" to="/withdraw">
                    RÚT VÀNG
                  </Link>
                </li>
              </Nav>

              {isAuthorized ? (
                <Nav className="navbar-nav ml-auto">
                  <li className="nav-item li-login">
                    <Link to="/profile" className="nav-link font-weight-bold btn btn-outline-light text-white">
                      <FontAwesomeIcon icon={faUser} /> {user?.name ?? 'Goku'} - <span className="user-balance">{currency(user?.balance)}</span>$
                    </Link>
                  </li>
                  <li className="nav-item">
                    <span className="nav-link font-weight-bold btn btn-outline-light text-white" onClick={() => dispatch(authActions.logout())}>
                      <FontAwesomeIcon icon={faSignOutAlt} /> ĐĂNG XUẤT
                    </span>
                  </li>
                </Nav>) : (
                <Nav className="navbar-nav ml-auto">
                  <li className="nav-item li-login">
                    <Link to="/sign-in" className="nav-link font-weight-bold btn btn-outline-light text-white">
                      <FontAwesomeIcon icon={faSignInAlt} /> ĐĂNG NHẬP
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/sign-up" className="nav-link font-weight-bold btn btn-outline-light text-white">
                      <FontAwesomeIcon icon={faUserPlus} /> ĐĂNG KÝ
                    </Link>
                  </li>
                </Nav>
              )}
            </Navbar.Collapse>

          </div>
        </Navbar>
      </div>
    </>
  )
}

export { Header }