import React, { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom';
import { LayoutSplashScreen, LayoutProvider } from './components/layout';
import AuthenticatedGuard from './modules/auth/guards/AuthenticatedGuard';
import SettingGuard from './modules/home/guards/SettingGuard';
import { Routes } from './routing/Routes';

type Props = {
  basename: string
}

const App: React.FC<Props> = ({ basename }) => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <SettingGuard>
        <AuthenticatedGuard>
          <BrowserRouter basename={basename}>
            <LayoutProvider>
              <Routes />
            </LayoutProvider>
          </BrowserRouter>
        </AuthenticatedGuard>
      </SettingGuard>
    </Suspense>);
}

export default App;
