import { guildLevelExps, vipLevels } from "app/contants";
import { IGuildMember } from "app/modules/guild/models/IGuildMember";

export function currency(value: any) {
  try {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } catch (error) {
    return 0;
  }
}

export function amount_string(value: any) {
  if (value >= 1000000000) return `${(value / 1000000000)} Tỉ`
  if (value >= 1000000) return `${(value / 1000000)}M`
  if (value >= 1000) return `${(value / 1000)}K`
  return 0
}

export function amount_string_fixed(value: any) {
  if (value >= 1000000000) return `${(value / 1000000000).toFixed(2)} Tỉ`
  if (value >= 1000000) return `${(value / 1000000).toFixed(2)}M`
  if (value >= 1000) return `${(value / 1000).toFixed(2)}K`
  return 0
}

export function formatTime(time: number) {
  if (time < 0) return "00:00";
  let timeString
  if (time >= 600) {
    timeString = Math.floor(time / 60) + ":" + ((time % 60) < 10 ? "0" + (time % 60) : (time % 60));
  } else if (time >= 60) {
    timeString = "0" + Math.floor(time / 60) + ":" + ((time % 60) < 10 ? "0" + (time % 60) : (time % 60));
  } else {
    timeString = "00:" + (time < 10 ? "0" + time : time);
  }
  return timeString;
}

export function expToLevel(exp: number) {
  var level = 1
  while (exp - guildLevelExps[level] >= 0) {
    exp -= guildLevelExps[level]
    level++
  }
  return [level, currency(exp) + '/' + currency(guildLevelExps[level + 1])]
}

export function calculateAchievement(arr: IGuildMember[]) {
  return amount_string(arr.reduce((total, current) => {
    return total + current.achievement
  }, 0))
}


export function getVipLevel(sum: number): number {
  var vip = 0
  vipLevels.forEach((item, index) => {
    if (sum >= item[0]) return vip = index + 1;
  })
  return vip;
}