import { useSocket } from 'app/components/core/Socket';
import { currency } from 'app/helpers';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import React, { useEffect, useState } from 'react';
import { xsmbActions } from '../redux/xsmbSlice';
import { BET_TYPES } from '../constants';
import { Spinner, Table } from 'react-bootstrap';
import { faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import XSMBCommand from 'app/commands/XSMBCommand';
import { History } from 'app/modules/luckyNumber/models/History';
import { ResultDetailModal } from './XSMBResultDetailModal';

export function XSMBHistory() {
  const dispatch = useAppDispatch()
  const { socket } = useSocket();
  const { xsmb, currentUser } = useAppSelector(({ xsmb, auth }) => ({
    xsmb: xsmb,
    currentUser: auth.currentUser
  }))
  const [personal, setPersonal] = useState(false)
  const [limit, setLimit] = useState(10)
  const [detailModal, setDetailModal] = useState(false)
  const [detail, setDetail] = useState<History>()
  const spinner = <Spinner animation="border" size="sm" variant="primary" />

  useEffect(() => {
    if (personal) {
      socket.on(XSMBCommand.XSMB_USER_HISTORY, (data) => {
        dispatch(xsmbActions.setHistory(data))
      })
    } else {
      socket.on(XSMBCommand.XSMB_HISTORY_PULL, (data) => {
        dispatch(xsmbActions.setHistory(data))
      })
    }

    return () => {
      socket.off(XSMBCommand.XSMB_HISTORY_PULL)
      socket.off(XSMBCommand.XSMB_USER_HISTORY)
    }
  }, [socket, personal])

  useEffect(() => {
    if (!personal) {
      socket.emit(XSMBCommand.XSMB_HISTORY_PULL)
    } else {
      socket.emit(XSMBCommand.XSMB_USER_HISTORY)
    }
  }, [personal, limit])


  function handleCancel(id: number) {
    // Swal.fire({
    //   title: 'Bạn chắc chứ?',
    //   text: "Bạn có muốn hủy đặt cược không?",
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonColor: '#3085d6',
    //   cancelButtonColor: '#d33',
    //   confirmButtonText: 'Chắc',
    //   cancelButtonText: 'Hủy'
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     socket.emit(XSMBCommand.XSMB_USER_CANCEL, { id: id })
    //   }
    // })
  }

  const handleShowHistory = (history: History) => {
    setDetail(history)
    setDetailModal(true)
  }

  return (
    <>
      <div className="row">
        <div className="text-center font-weight-bold w-100 mb-1 mt-2">
          <h4 id="darkMode4" className="text-dark">Lịch sử đặt cược</h4>
        </div>
        <div className="table-responsive bet-history">
          <Table striped bordered className="text-center">
            <thead className="bg-info text-white">
              <tr>
                <th scope="col" className="nowrap">Nhân vật</th>
                <th scope="col" className="nowrap">Tình trạng</th>
                <th scope="col" className="nowrap">Vàng đặt</th>
                <th scope="col" className="nowrap">Loại</th>
                <th scope="col" className="nowrap">Dự đoán</th>
                <th scope="col" className="nowrap">Kết quả</th>
                <th scope="col" className="nowrap">Vàng nhận</th>
                <th scope="col" className="nowrap">Thời gian</th>
              </tr>
            </thead>
            <tbody>
              {xsmb.histories.map((history, key) => {
                return (key < limit) && (
                  <tr key={key}>
                    <td>{history.name ?? history.meta.name}</td>
                    <td>{history.status === 0 ? spinner : <span className={`badge badge-${history.status === 1 ? 'success' : 'danger'}`}>{history.status === 1 ? 'Thắng' : (history.status === 2 ? 'Thua' : 'Hủy')}</span>}</td>
                    <td>{currency(history.amount)}</td>
                    <td>{BET_TYPES[history.type]}</td>
                    <td>
                        {history.numbers.map(number => {
                            return (
                            <>
                                <span className={`font-weight-bold text-${history.extra.map((item) => parseInt(item.toString().substr(-2))).indexOf(number) !== -1 ? 'success' : 'danger'}`}>{number < 10 ? `0${number}` : number}</span>
                                {" "}
                            </>
                            )
                        })}
                    </td>
                    <td>
                      {history.status === 0 && spinner}
                      {(history.status === 1 || history.status === 2) && (
                        <span style={{ cursor: 'pointer' }} className="text-primary" onClick={() => handleShowHistory(history)}>
                          <FontAwesomeIcon icon={faEye} />
                        </span>
                      )}
                    </td>

                    <td>{history.amount_won ? currency(history.amount_won) : 0}</td>
                    <td>{((history.name ?? history.meta.name) === currentUser?.name && history.status === 0) ? (
                      <span style={{ cursor: 'pointer' }} className="text-primary" onClick={() => handleCancel(history.id)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </span>
                    ) : history.created_at}</td>
                  </tr>
                )
              }
              )}
            </tbody>
          </Table>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-2 col text-dark">
          <label>Hiện thị:</label>
          <select id="isMe" className="form-control" onChange={(e) => setPersonal(e.target.value === '1' ? true : false)}>
            <option value="0">Tất cả</option>
            <option value="1">Mình tôi</option>
          </select>
        </div>
        <div className="col offset-lg-8 col text-dark" id="darkMode6">
          <label>Bản ghi:</label>
          <select id="record" className="form-control" onChange={(e) => setLimit(parseInt(e.target.value ?? '10'))}>
            <option value="10">10 bản ghi</option>
            <option value="20">20 bản ghi</option>
            <option value="30">30 bản ghi</option>
            <option value="50">50 bản ghi</option>
            <option value="100">100 bản ghi</option>
          </select>
        </div>
      </div>
      <ResultDetailModal display={detailModal} setDisplay={setDetailModal} numbers={detail && detail.extra}/>
    </>
  )
}
