import React, { useEffect, useState } from 'react'
import { Button, FormGroup, FormLabel, Modal } from 'react-bootstrap'
import { OrderInfoModel } from '../models/OrderInfoModel'


interface OrderInfoProps {
  info: OrderInfoModel[]
  display: boolean
  setDisplay: (value: boolean) => void
}

export function PKGameOrderInfo({ info, display, setDisplay }: OrderInfoProps) {

  return (
    <Modal show={display} onHide={() => setDisplay(false)}>
      <Modal.Header closeButton>
        <div className="col text-center">
          <span className="font-weight-bold">Nhật ký đơn hàng</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="pb-2">
          {info.map(item => 
            (
              <div className="d-flex align-items-center">
                <span className="bg-info mr-2" style={{width: '9px', height: '9px', borderRadius: '100%'}}></span>
                <div className="d-flex align-items-center justify-content-between my-1" style={{ flexGrow: 1 }}>
                  <span>{item.description}</span>
                  <span className="text-muted font-italic text-right">{item.created_at.substr(0,8)}</span>
                </div>
              </div>
            )
          )}
        </div>
      </Modal.Body>
    </Modal >
  )
}