import { useAppSelector } from 'app/hooks';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

export function Notice() {
  const [display, setDisplay] = useState(true)
  const { setting } = useAppSelector(({ home }) => ({ setting: home.setting }))

  return (
    <Modal show={display} onHide={() => setDisplay(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Hướng dẫn</Modal.Title>
      </Modal.Header>
      <Modal.Body dangerouslySetInnerHTML={{ __html: setting.popup_content }}></Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setDisplay(false)}>
          Đóng
        </Button>
      </Modal.Footer>
    </Modal >
  )
}