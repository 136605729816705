import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Message } from "../../luckyNumber/models/Message";
import { IServer } from "../../luckyNumber/models/Server"
import { History } from "../../luckyNumber/models/History";
import { PaginationParams } from "../../luckyNumber/models/PaginationParams";

export interface PickMeState {
  timeLeft: number;
  totalPlayer: number;
  histories: any[];
}

const INITIAL_STATE: PickMeState = {
  timeLeft: 0,
  totalPlayer: 0,
  histories: []
};

const pickMeSlice = createSlice({
  name: "pickMe",
  initialState: INITIAL_STATE,
  reducers: {
    setTurnInfo(state, action: PayloadAction<any>) {
      try {
        state.timeLeft = action.payload.time;
        state.totalPlayer = action.payload.totalPlayer
      } catch (error) {
        console.error(error);
      }
    },
    setHistory(state, action: PayloadAction<any>) {
      state.histories = action.payload.slice().reverse()
    },
  },
});

export const pickMeActions = pickMeSlice.actions;

export const pickMeReducer = pickMeSlice.reducer;

