import { JarOfGoldButton } from 'app/modules/jarOfGold/components/JarOfGoldButton';
import { PickMeButton } from 'app/modules/pickMe/components/PickMeButton';
import React from 'react';
import { EnvelopeUserButton } from './EnvelopeUserButton';

export function MenuChat() {
  return (
    <>
      <div className="row m-0" style={{ padding: '2px 0px 2px 0px' }}>
        <div className="col">
          <div className="text-left">

          </div>
        </div>
        {/* <div className="pr-1">
          <div className="text-right" style={{ border: 'none' }}>
            <JarOfGoldButton/>
            <EnvelopeUserButton />
            <PickMeButton></PickMeButton>
          </div>
        </div> */}
      </div>
      <hr style={{ width: '100%', textAlign: 'left' }} className="bg-primary" />
    </>
  )
}