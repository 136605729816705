import axios from "axios";

export const HISTORY_URL = `/payment/history`;
export const TRANSACTION_URL = `/transactions`;
export const TRADE_GOLD_URL = `/payment/trade-gold/accounts`;
export const TRADE_GOLD_STORE_URL = `/payment/trade-gold/store`;

export const TRADE_GOLD_BAR_URL = `/payment/trade-gold-bar/accounts`;
export const TRADE_GOLD_BAR_STORE_URL = `/payment/trade-gold-bar/store`;

export const WITHDRAW_TRADE_GOLD_URL = `/withdraw/trade-gold/accounts`;
export const WITHDRAW_TRADE_GOLD_STORE_URL = `/withdraw/trade-gold/store`;
export const WITHDRAW_TRADE_GOLD_LIMIT_URL = `/withdraw/trade-gold/limit`;

export const CANCEL_URL = `/payment/cancel`;

export const WALLET_INFO_URL = `/payment/wallet/info`;
export const WALLET_HISTORY_URL = `/payment/wallet/history`;

export const CHARGING_RATE_URL = `/charging/rate`;
export const CHARGING_STORE_URL = `/charging/store`;
export const CHARGING_HISTORY_URL = `/charging/history`;

export const TRANSFER_STORE_URL = `/transfer/store`;
export const TRANSFER_HISTORY_URL = `/transfer/history`;

export function fetchHistory({ page, type }: any) {
	return axios.get(`${HISTORY_URL}?page=${page}&type=${type}`);
}

export function fetchTransactions(page: number) {
	return axios.get(`${TRANSACTION_URL}?page=${page}`);
}

export function fetchTradeGoldBot() {
	return axios.get(`${TRADE_GOLD_URL}`);
}

export function fetchTradeGoldBarBot() {
	return axios.get(`${TRADE_GOLD_BAR_URL}`);
}

export function fetchWithdrawTradeGoldBot() {
	return axios.get(`${WITHDRAW_TRADE_GOLD_URL}`);
}

export function storeTradeGoldBot({ amount, ingame }: any) {
	return axios.post(`${TRADE_GOLD_STORE_URL}`, { amount: amount, ingame: ingame });
}

export function storeTradeGoldBar({ amount, ingame }: any) {
	return axios.post(`${TRADE_GOLD_BAR_STORE_URL}`, { amount: amount, ingame: ingame });
}

export function storeWithdrawTradeGold({ amount, ingame }: any) {
	return axios.post(`${WITHDRAW_TRADE_GOLD_STORE_URL}`, { amount: amount, ingame: ingame });
}

export function getLimitWithdrawTradeGold() {
	return axios.get(`${WITHDRAW_TRADE_GOLD_LIMIT_URL}`);
}

export function cancelPayment(order_id: number) {
	return axios.post(`${CANCEL_URL}`, { order_id: order_id });
}

export function fetchChargingRate() {
	return axios.get(`${CHARGING_RATE_URL}`);
}

export function storeCharging({ telco, amount, code, serial }: any) {
	return axios.post(`${CHARGING_STORE_URL}`, { telco, amount, code, serial });
}

export function fetchChargingHistory(page: number) {
	return axios.get(`${CHARGING_HISTORY_URL}?page=${page}`);
}

export function fetchWalletInfo(type: number) {
	return axios.get(`${WALLET_INFO_URL}?type=${type}`);
}

export function fetchWalletHistory({ page, type }: any) {
	return axios.get(`${WALLET_HISTORY_URL}?page=${page}&type=${type}`);
}


export function storeTransfer({ username, amount, description }: any) {
	return axios.post(`${TRANSFER_STORE_URL}`, { username, amount, description });
}

export function fetchTransferHistory(page: number) {
	return axios.get(`${TRANSFER_HISTORY_URL}?page=${page}`);
}