import axios from "axios";

export const PLAY_URL = `/wheel/play`;
export const FREE_PLAY_URL = `/wheel/play-free`;
export const HISTORY_URL = `/wheel/history`;

export function fetchWheelHistory({ type, page, personal }: any) {
	return axios.get(`${HISTORY_URL}?type=${type}&page=${page}&personal=${personal}`);
}

export function wheelPlay({ type }: any) {
	return axios.post(`${PLAY_URL}`, { type: type });
}

export function freeWheelPlay({ type }: any) {
	return axios.post(`${FREE_PLAY_URL}`, { type: type });
}