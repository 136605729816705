import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserModel } from '../models/User'

export interface LoginPayload {
	username: string;
	password: string;
}

export interface RegisterPayload {
	username: string;
	email: string;
	password: string;
}

export interface UpdatePayload {
	name: string;
	server_id: number;
	avatar_id?: number;
	referral_code: string;
}

export interface PasswordPayload {
	currentPassword: string;
	newPassword: string;
}

export interface AuthState {
	isLoggedIn: boolean;
	logging?: boolean;
	currentUser?: UserModel;
	token?: string;
}

const INITIAL_STATE: AuthState = {
	isLoggedIn: false,
	logging: false,
	currentUser: undefined,
};

const authSlice = createSlice({
	name: "auth",
	initialState: INITIAL_STATE,
	reducers: {
		login(state, action: PayloadAction<LoginPayload>) {
			state.logging = true;
		},
		loginSuccess(state, action: PayloadAction<UserModel>) {
			state.logging = false;
			state.currentUser = action.payload;
		},
		setBalance(state, action: PayloadAction<number>){
			if(state.currentUser){
				state.currentUser.balance = action.payload
			}
		},
		setDiamond(state, action: PayloadAction<number>){
			if(state.currentUser){
				state.currentUser.diamond = action.payload
			}
		},
		setStreak(state, action: PayloadAction<number>){
			if(state.currentUser){
				state.currentUser.streak = action.payload
			}
		},
		loginFailed(state) {
			state.logging = false;
		},
		register(state, action: PayloadAction<RegisterPayload>) {
			state.logging = true;
		},
		registerSuccess(state, action: PayloadAction<UserModel>) {
			state.logging = false;
			state.currentUser = action.payload;
		},
		registerFailed(state) {
			state.logging = false;
		},
		password(state, action: PayloadAction<PasswordPayload>) {
			state.logging = true;
		},
		passwordDone(state) {
			state.logging = false;
		},
		logout(state) {
			state.isLoggedIn = false;
			state.currentUser = undefined;
		},
		setUser(state, action: PayloadAction<any>) {
			state.currentUser = action.payload;
		},
		setToken(state, action: PayloadAction<string>) {
			state.token = action.payload;
		}
	},
});

export const authActions = authSlice.actions;

export const selectIsLoggedIn = (state: any) => state.auth.isLoggedIn;
export const selectIsLogging = (state: any) => state.auth.logging;

export const authReducer = authSlice.reducer;

