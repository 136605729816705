import { useSocket } from 'app/components/core/Socket';
import { useLayout } from 'app/components/layout';
import { SOCKET_CMD } from 'app/contants';
import LuckyNumberCommand from 'app/commands/LuckyNumberCommand'
import { useAppDispatch, useAppSelector } from 'app/hooks';
import React, { useEffect, useState } from 'react';
import { luckyNumberActions } from '../redux/luckyNumberSlice';

export function ListServerMain() {
  const dispatch = useAppDispatch();
  const { socket } = useSocket()
  const { setDicePopup, setDiceLivePopup } = useLayout()
  const { currentUser, currentServer, listServer } = useAppSelector(({ lucky, auth }) => ({
    listServer: lucky.listServer,
    currentServer: lucky.currentServer,
    currentUser: auth.currentUser
  }))

  function changeServer(server_id: number) {
    window.localStorage.setItem('server_id', server_id.toString())

    if(server_id === 27){
      setDicePopup(true)
    }else if(server_id === 28){
      setDiceLivePopup(true)
    }

    socket.emit(LuckyNumberCommand.LUCKY_CHOOSE_SERVER, {
      server: server_id
    });
    dispatch(luckyNumberActions.setCurrentServer(server_id))
  }

  useEffect(() => {
    changeServer(parseInt(window.localStorage.getItem('server_id') ?? "25"));

    socket.emit(LuckyNumberCommand.LUCKY_LIST_SERVER)

    socket.on(LuckyNumberCommand.LUCKY_LIST_SERVER, (data) => {
      dispatch(luckyNumberActions.setListServer(data))
    })

    socket.on(LuckyNumberCommand.LUCKY_TURN_INFO, (data) => {
      dispatch(luckyNumberActions.setTurnInfo(data))
    })

    socket.io.on("reconnect", () => {
      changeServer(parseInt(window.localStorage.getItem('server_id') ?? "25"));
    })

    return () => {
      socket.off(LuckyNumberCommand.LUCKY_TURN_INFO)
      socket.io.off("reconnect")
    }

  }, [socket])

  return (
    <>
      <div className="row mt-2 mb-lg-2">
        <div className="col text-center">
          {listServer.map(item => (
            <button key={item.id} onClick={() => changeServer(item.id)} className={`btn btn-server btn${item.id !== currentServer ? '-outline' : ''}-info`}>{item.name}</button>
          ))}
        </div>
      </div>

      <div className="row mt-2 mb-lg-2">
        <div className="col text-center">
          {/*<button onClick={() => changeServer(27)} className={`btn btn-server btn${27 !== currentServer ? '-outline' : ''}-info`}>TÀI XỈU</button>*/}
          <button onClick={() => changeServer(29)} className={`btn btn-server btn${29 !== currentServer ? '-outline' : ''}-info`}>CSMM SIÊU CẤP</button>
        </div>
      </div>
    </>
  );
}
