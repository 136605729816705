import React from 'react';
import { faGavel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function PKGameGuide(){
    return (
        <div className="card border-info ">
            <div className="card-header bg-info text-light border-seccond text-center font-weight-bold">
                <FontAwesomeIcon icon={faGavel} /> HƯỚNG DẪN CHƠI
            </div>
            <div className="card-body bg-light text-dark" id="cardBodyKetQua">
                <p className="card-text">
                    <span className="font-weight-bold text-danger">- B1:</span> Vào vị trí BOT Đỏ Đen bên dưới<br/>
                    <span className="font-weight-bold text-danger">- B2:</span> Mời giao dịch với BOT để đặt cược<br/>
                    <span className="font-weight-bold text-danger">- B3:</span> Chưởng vào bot để lấy kết quả<br/><br/>
                    <span className="font-weight-bold text-danger">Lưu ý:</span> Số cuối của vàng bạn giao dịch cho bot chính là cửa bạn chọn<br/>
                    <span className="font-weight-bold text-danger">Ví dụ:</span> Bạn giao dịch 200.000.001 tức là bạn cược Đen<br/>
                </p>
            </div>
        </div>
    )
}