import GuildCommand from 'app/commands/GuildCommand';
import { useAppSelector } from 'app/hooks';
import { DiceItem } from 'app/modules/dice/components/DiceItem';
import { GuildPopup } from 'app/modules/guild/components/GuildPopup';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLayout } from '.';
import { useSocket } from '../core/Socket';

export function Footer() {
  const { setting } = useAppSelector(({ home }) => ({ setting: home.setting }))
  const youtubers = JSON.parse(setting.youtubers ?? '[]')

  return (
    <footer className="container-fluid bg-dark text-left text-lg-start text-white" style={{ marginTop: '50px' }}>
      <div className="container">
        <div className="row pr-0 pl-0" id="row-footer">
          <div className="col-lg-6">
            <div className="mb-4 mb-md-0">
              <h5 className="text-uppercase">VỀ TDST24H</h5>
              <p>
                Mini Game Kiếm Vàng Hồi Sinh Ngọc Rồng Uy Tín - Minh Bạch<br />
                Hoạt động tự động 100%<br />
                <Link to="terms" className="font-weight-bold text-white">Điều khoản</Link>
                <a href={setting.fanpage} className="text-white font-weight-bold">Chăm sóc khách hàng 24/24</a>
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="mb-4 mb-md-0">
              <h5 className="text-uppercase">TDST24H.COM ĐƯỢC</h5>
              <p className="pb-0">
                Đảm bảo uy tín từ các Youtuber<br />
                {youtubers.map((item: string, index: number) => (
                  <span key={index}>
                    <strong>{index + 1}.</strong> <span className="text-white font-weight-bold">{item}</span><br />
                  </span>
                ))}
              </p >
            </div >
          </div >
        </div >
        <div className="text-center"><small>Copyright 2021 © TDST24H.COM | All rights reserved</small></div>
      </div >



    </footer >
  )
}
