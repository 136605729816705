import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Message } from "app/modules/luckyNumber/models/Message";
import { DiceHistoryModel } from "app/modules/dice/models/DiceHistoryModel";

export interface DiceState {
  timeLeft: number;
  turnId: number;
	lastResult: number;
	miniResults: number[];
	messages: Message[];
	betByTypes: number[];
	userBetByTypes: number[];
	histories: DiceHistoryModel[];
}

const INITIAL_STATE: DiceState = {
	timeLeft: 0,
	turnId: 0,
	lastResult: 0,
	miniResults: [],
	messages: [],
	betByTypes: [],
	userBetByTypes: [],
	histories: []
};

const diceLiveSlice = createSlice({
	name: "diceLive",
	initialState: INITIAL_STATE,
	reducers: {
		setTurnInfo(state, action: PayloadAction<any>) {
			try {
				state.timeLeft = action.payload.time;
				state.turnId = action.payload.id;
				state.lastResult = action.payload.lastResult;
				state.miniResults = action.payload.miniResults.slice().reverse();
				state.betByTypes = action.payload.types
				state.userBetByTypes = action.payload.usersBet
			} catch (error) {
				console.error(error);
			}
		},
		setMessages(state, action: PayloadAction<Message[]>) {
			state.messages = action.payload;
		},
		setMessage(state, action: PayloadAction<Message>) {
			if(state.messages.length >= 50) state.messages.shift();
			state.messages.push(action.payload);
		},
		setHistory(state, action: PayloadAction<DiceHistoryModel[]>) { //PaginationParams<History>
			state.histories = action.payload.slice().reverse()
		},
	},
});

export const diceLiveActions = diceLiveSlice.actions;

export const diceLiveReducer = diceLiveSlice.reducer;

