export interface Telco {
  name: string;
  rate: number;
}

export const ListTelco = [
  {
    name: "Viettel",
    rate: 4000,
  },
  {
    name: "Vinaphone",
    rate: 4000,
  },
  {
    name: "Mobifone",
    rate: 4000,
  }
]