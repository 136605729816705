import React, { useEffect } from 'react'
import { Content } from './Content'
import { Header } from './Header'
import { Footer } from './Footer'
import { GuildPopup } from 'app/modules/guild/components/GuildPopup'
import { DicePopup } from 'app/modules/dice/components/DicePopup'
import { ToastContainer } from 'react-toastify'
import { GuildEntry } from 'app/modules/guild/components/GuildEntry'
import { darkTheme, GlobalStyles, lightTheme, useLayout } from '.'
import { ThemeProvider } from 'styled-components'

const MasterLayout: React.FC = ({ children }) => {
  const { darkMode, setDarkMode, setDicePopup } = useLayout()

  useEffect(() => {
   setDarkMode(localStorage.getItem('darkMode') === 'true')
  }, [])

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <GlobalStyles/>
      <Header />
      <div className="container">
        <Content>{children}</Content>
      </div>
      {/*<GuildPopup />*/}
      {/*<DicePopup />*/}
      {/*<GuildEntry />*/}
      <ToastContainer />
      <Footer></Footer>
    </ThemeProvider>
  )
}

export { MasterLayout }
