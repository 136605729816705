import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLayout } from 'app/components/layout';
import { currency } from 'app/helpers';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import { cancelPayment, fetchHistory } from '../redux/paymentApi';
import { paymentActions } from '../redux/paymentSlice';

export interface HistoryProps {
  type: number
}

export function History({ type }: HistoryProps) {
  const dispatch = useAppDispatch()
  const { setAlertModal, setAlertContent } = useLayout()
  const payment = useAppSelector(({ payment }) => payment)
  const spinner = (<FontAwesomeIcon icon={faSpinner} size="lg" spin />);
  const { loading, counter } = useAppSelector(({ payment }) => ({
    loading: payment.loading,
    counter: payment.counter,
  }))
  const [canceling, setCanceling] = useState(false)

  useEffect(() => {
    reloadHistory(payment.histories.current_page)
  }, [type, counter])

  function reloadHistory(page: number) {
    dispatch(paymentActions.setLoading(true))
    fetchHistory({ page: page, type: type }).then((data) => {
      dispatch(paymentActions.setHistory(data))
    }).finally(() => {
      dispatch(paymentActions.setLoading(false))
    })
  }

  function handleCancel(id: number) {
    setCanceling(true)
    cancelPayment(id).finally(() => {
      dispatch(paymentActions.refreshHistory())
      setCanceling(false)
    }).catch(error => {
      setAlertModal(true);
      setAlertContent(error.message);
    })
  }

  return (
    <>
      <Table bordered responsive>
        <thead className="bd-info">
          <tr className="title-table">
            <th>Server</th>
            <th>Nhân vật</th>
            <th>Số vàng</th>
            <th>Tình trạng</th>
            <th>Thời gian</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (<tr className="text-center"><td colSpan={6}>Đang tải...</td></tr>) :
            payment.histories.data.map((history, key) => (
              <tr className="table-row" key={key}>
                <td>Máy chủ {history.server}</td>
                <td>{history.ingame}</td>
                <td>{currency(history.amount)}</td>
                <td>{history.status === 0 ? spinner : (<span className={`badge badge-${history.status === 1 ? 'success' : 'danger'}`}>{history.status === 1 ? 'Thành công' : 'Hủy bỏ'}</span>)}</td>
                <td>{history.status === 0 && type !== 2 ? (
                  canceling ? spinner : (<span className="btn btn-danger btn-sm" style={{ fontSize: '.65rem' }} onClick={() => handleCancel(history.id)}>Hủy đơn</span>)
                ) : history.created_at}</td>
              </tr>
            ))
          }
          {payment.histories.data.length === 0 && !loading ? <tr className="text-center font-weight-bold pt-5"><td colSpan={6}>Không có dữ liệu</td></tr> : null}
        </tbody>
      </Table>

      <div className="his-nav">
        <Pagination
          activePage={payment.histories.current_page}
          itemsCountPerPage={payment.histories.per_page}
          totalItemsCount={payment.histories.total}
          pageRangeDisplayed={5}
          onChange={(page: number) => reloadHistory(page)}
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>
    </>
  )
}
