import { faSignInAlt, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { authActions } from './redux/authSlice';
import { Input } from "app/components/partials/form/Input";
import { Link } from 'react-router-dom';

export function SignUp() {
  const dispatch = useAppDispatch();

  const isLogging = useAppSelector(state => state.auth.logging);

  const handleSubmit = (values: any) => {
    const counter = localStorage.getItem('account_counter')
    if (counter && counter.length >= 2) {
      let el = document.getElementById('error-register')
      if (el) el.innerHTML = "Bạn chỉ có thể tạo tối đa 2 tài khoản"
    } else {
      dispatch(
        authActions.register(values)
      )
    }
  }

  const initialValues = {
    username: "",
    email: "",
    password: "",
  };

  const Schema = Yup.object().shape({
    username: Yup.string()
      .matches(/^[a-zA-Z0-9]+$/, "Tài khoản không hợp lệ")
      .min(5, "Tối thiểu 6 ký tự")
      .required("Không được bỏ trống"),
    password: Yup.string()
      .min(5, "Tối thiểu 6 ký tự")
      .required("Không được bỏ trống"),
    email: Yup.string()
      .email("Địa chỉ email không hợp lệ")
  });


  return (
    <div className="form pt-5">
      <div className="row justify-content-center">
        <h3>Đăng ký</h3>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <div className="text-danger text-center font-weight-bold" id="error-register"></div>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={Schema}
            onSubmit={(values) => {
              handleSubmit(values)
            }}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit} style={{ padding: '10px 20px' }}>
                <div className="form-group">
                  <label>Tài khoản</label>
                  <Field name="username" className="form-control" placeholder="Tên đăng nhập" component={Input} autoComplete="off" />
                </div>
                <div className="form-group">
                  <label>Địa chỉ Email</label>
                  <Field name="email" className="form-control" placeholder="Địa chỉ Email ( không bắt buộc)" component={Input} autoComplete="off" />
                </div>
                <div className="form-group">
                  <label>Mật khẩu</label>
                  <Field type="password" name="password" className="form-control" placeholder="Mật khẩu" component={Input} autoComplete="off" />
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-info form-control font-weight-bold text-uppercase" disabled={isLogging}>
                    <FontAwesomeIcon icon={faUserPlus} /> Đăng ký</button>
                </div>
                <div className="form-group text-center">
                  Bạn đã có tài khoản?
                  <Link to="/sign-in" className="btn btn-danger font-weight-bold text-uppercase">
                    <FontAwesomeIcon icon={faSignInAlt} /> Đăng nhập
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}