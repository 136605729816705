import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Modal } from 'react-bootstrap'
import { XSMBResultTable } from './XSMBResult'

interface ResultDetailModalProps {
  numbers?: string[] | number[]
  display: boolean
  setDisplay: (value: boolean) => void
}

export function ResultDetailModal({ numbers, display, setDisplay }: ResultDetailModalProps) {

  if(!numbers) return <></>

  return (
    <Modal show={display} onHide={() => setDisplay(false)}>
      <Modal.Body>
        <div className="text-right" style={{ marginTop: '-8px' }}>
          <FontAwesomeIcon icon={faTimes} style={{ cursor: 'pointer'}} onClick={() => setDisplay(false)}/>
        </div>
        <div className="text-center pb-2">
          <h5 className="pb-2">Chi tiết kết quả</h5>
          <XSMBResultTable results={numbers}/>
          {/* <table className="table text-center" style={{marginBottom: '0px'}}>
            <tbody>
              <tr>
                <td style={{textAlign: 'left'}}><b>Số xu tham gia:</b></td>
                <td>
                  <span className="font-weight-bold text-danger">123</span>
                </td>
              </tr>
            </tbody>
          </table> */}
        </div>
      </Modal.Body>
    </Modal >
  )
}
