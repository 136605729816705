import React from 'react';
import { currency } from 'app/helpers';

interface Props {
  amount: number;
  setAmount: (value: number) => void
}

export function Envelope({ amount, setAmount }: Props) {
  return (
    <div className="overlay-lucky" style={{ display: amount > 0 ? 'block' : 'none' }} onClick={() => setAmount(0)}>
      <div className="light"></div>
      <div className="result">
        <img src="/images/lucky/lucky-money-result.png"></img>
        <div className="amount">
          {currency(amount)}
        </div>
      </div>
    </div>
  )
}