import React, { useState } from 'react';
import { faGamepad, faGavel, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector } from 'app/hooks';
import { currency } from 'app/helpers';
import { Alert, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GameButtonType } from './GameButtonType'
import CurrencyInput from 'react-currency-input-field';
import LuckyNumberCommand from 'app/commands/LuckyNumberCommand';
import { useSocket } from 'app/components/core/Socket';
import { useLayout } from 'app/components/layout';
import gold from '../../../../assets/images/gold.png';

export function GameForm() {
  const { response, alertNotice } = useLayout()
  const { socket } = useSocket();
  const [currentGame, setCurrentGame] = useState('1')
  const [currentType, setCurrentType] = useState(1)
  const [amount, setAmount] = useState('')
  const [number, setNumber] = useState('')
  const { isAuthorized, currentUser, currentServer } = useAppSelector(({ auth, lucky }) => ({
    isAuthorized: auth.currentUser !== undefined,
    currentUser: auth.currentUser,
    currentServer: lucky.currentServer
  }))

  function handlePlay(e: any) {
    e.preventDefault()
    if (amount === '' || parseInt(amount) < 10) {
      alertNotice('Số tiền đặt cược tối thiểu là 10 thỏi vàng')
    }
    if (amount === '' || parseInt(amount) > 666) {
      alertNotice('Số tiền đặt cược tối thiểu là 666 thỏi vàng')
    }
    if (currentGame === '3') {
      if (number === '' || isNaN(parseInt(number)) || parseInt(number) < 0 || parseInt(number) > 99) {
        alertNotice('số dự đoán phải từ 00-99')
      } else {
       console.log(LuckyNumberCommand.LUCKY_USER_PLAY,{ type: 9, number: number, amount: amount, server: currentServer })
        socket.emit(LuckyNumberCommand.LUCKY_USER_PLAY, { type: 9, number: number, amount: amount, server: currentServer })
      }
    } else {
      console.log(LuckyNumberCommand.LUCKY_USER_PLAY,{ type: currentType, amount: amount, server: currentServer })
      socket.emit(LuckyNumberCommand.LUCKY_USER_PLAY, { type: currentType, amount: amount, server: currentServer })
    }
    setAmount('')
  }

  return (
    <div className="card border-info mt-3">
      <div className="card-header bg-info text-light border-seccond text-center font-weight-bold">
        <FontAwesomeIcon icon={faGavel} /> ĐẶT CƯỢC
      </div>
      <div className="card-body border-seccond bg-light text-dark" style={{ padding: "30px 15px" }}>
        <div className="input-group form-group">
          <span className="input-group-prepend">
            <div className="input-group-text bg-light border-right-0 text-warning">
             <img src={gold} alt="Mini Game Ngọc Rồng Online" style={{ maxHeight: "calc(0.5em + 0.75rem + 2px)"}}/>
            </div>
          </span>
          <div className="form-control py-2 border-left-0 border readonly">{currency(currentUser?.balance ?? 0)}</div>
        </div>
        <form onSubmit={handlePlay}>
          <div className="input-group form-group">
            <span className="input-group-prepend">
              <div className="input-group-text bg-light border-right-1" style={{ color: 'red', padding: "6px 10px" }}>
                <FontAwesomeIcon icon={faGamepad} />
              </div>
            </span>
            <select className="form-control py-2 border-left-0 border text-danger" name="type" onChange={(e) => setCurrentGame(e.target.value)}>
              <option value="1">Đỏ đen(10tv ăn 19tv)</option>
            </select>
          </div>

          <label>Kết quả dự đoán: </label>
          <div className="form-group" id="divValue">
            {(currentGame === '1' || currentGame === '2') && (
              <div className="btn-group" style={{ width: "100%" }}>
                <div className="row" style={{ width: "100%" }}>
                  {currentGame === '1' && (
                    <>
                      <GameButtonType name="ĐỎ (Red)" variant="danger" onClick={() => setCurrentType(1)} show={currentType === 1} />
                      <GameButtonType name="ĐEN (Black)" variant="dark" onClick={() => setCurrentType(2)} show={currentType === 2} />
                      {/* {currentServer === 24 && (
                          <>
                          <GameButtonType name="TÀI" variant="success" onClick={() => setCurrentType(3)} show={currentType === 3} />
                          <GameButtonType name="XỈU" variant="danger" onClick={() => setCurrentType(4)} show={currentType === 4} />
                          </>
                      )} */}
                    </>
                  )}
                  {currentGame === '2' && (
                    <>
                      <GameButtonType name="ĐỎ - TÀI" variant="info" onClick={() => setCurrentType(5)} show={currentType === 5} />
                      <GameButtonType name="ĐỎ - XỈU" variant="warning" onClick={() => setCurrentType(6)} show={currentType === 6} />
                      <GameButtonType name="ĐEN - TÀI" variant="success" onClick={() => setCurrentType(7)} show={currentType === 7} />
                      <GameButtonType name="ĐEN - XỈU" variant="danger" onClick={() => setCurrentType(8)} show={currentType === 8} />
                    </>
                  )}
                </div>
              </div>
            )}
            {currentGame === '3' && (
              <div className="form-group">
                <input type="number" name="value" onChange={(e) => setNumber(e.target.value)} placeholder="Nhập kết quả dự đoán bằng số" className="form-control" />
              </div>
            )}
          </div>
          <div className="input-group form-group">
            <span className="input-group-prepend" style={{ borderRight: '1px solid red' }}>
              <div className="input-group-text bg-light border-right-1">
                <FontAwesomeIcon icon={faGavel} />
              </div>
            </span>
            <CurrencyInput value={amount} allowNegativeValue={false} type="search" className="form-control py-2 border-left-0 border font-weight-bold amount-play" placeholder="Nhập số tiền đặt cược" onValueChange={(value) => setAmount(value ?? '')} />
          </div>

          {response.message && <Alert variant={response.type}>{response.message}</Alert>}

          {!isAuthorized ? (
            <Link to="/sign-in" className="btn btn-danger font-weight-bold text-uppercase form-control">
              <FontAwesomeIcon icon={faSignInAlt} /> ĐĂNG NHẬP ĐỂ THỰC HIỆN
            </Link>
          ) :
            <Button type="submit" variant="info" className="font-weight-bold form-control">ĐẶT NGAY</Button>
          }

        </form>
      </div>
    </div>
  )
}
