export default {
  LUCKY_LIST_SERVER: 'LUCKY_LIST_SERVER',
  LUCKY_CHOOSE_SERVER: 'LUCKY_CHOOSE_SERVER',
  LUCKY_TURN_INFO: 'LUCKY_TURN_INFO',
  LUCKY_TURN_RESULT: 'LUCKY_TURN_RESULT',
  LUCKY_USER_PLAY: 'LUCKY_USER_PLAY',
  LUCKY_CHAT_PUSH: 'LUCKY_CHAT_PUSH',
  LUCKY_CHAT_PULL: 'LUCKY_CHAT_PULL',
  LUCKY_HISTORY_PULL: 'LUCKY_HISTORY_PULL',
  LUCKY_USER_HISTORY: 'LUCKY_USER_HISTORY',
  LUCKY_CHEAT_RESULT: 'LUCKY_CHEAT_RESULT',
  LUCKY_USER_CANCEL: 'LUCKY_USER_CANCEL',
  LUCKY_DISABLE:'LUCKY_DISABLE',
  SERVER_CLOSED:'SERVER_CLOSED'
}