import { useAppSelector } from 'app/hooks'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'

interface GameGuideProps {
  content: string
  display: boolean
  setDisplay: (value: boolean) => void
}

export function GameGuide({ content, display, setDisplay }: GameGuideProps) {
  return (
    <Modal show={display} onHide={() => setDisplay(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Hướng dẫn</Modal.Title>
      </Modal.Header>
      <Modal.Body dangerouslySetInnerHTML={{ __html: content }}></Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setDisplay(false)}>
          Đóng
        </Button>
      </Modal.Footer>
    </Modal >
  )
}