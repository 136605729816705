import { useAppSelector } from 'app/hooks'
import React, { useEffect, useState } from 'react'

export function Introduction() {
  const [youtuber, setYoutuber] = useState('')
  const [index, setIndex] = useState(0)
  const { setting } = useAppSelector(({ home }) => ({ setting: home.setting }))
  const youtubers = JSON.parse(setting.youtubers ?? '[]')

  useEffect(() => {
    const loopInterval = setInterval(() => {
      setIndex(index => index + 1)
    }, 1000)
    return () => { clearInterval(loopInterval) }
  }, [])

  useEffect(() => {
    if (index >= youtubers.length) return setIndex(0)
    setYoutuber(youtubers[index])
  }, [index])

  return (
    <>
      <h5 className="text-center mt-4 text-dark" style={{ margin: '0px' }}>
        HỆ THỐNG MINI GAME
      </h5>
      <p className="text-center text-dark mb-1">
        Được đảm bảo uy tín từ <br />
        <span className="font-weight-bold">
          Youtuber {youtuber}
        </span>
      </p>
      <hr style={{ width: '15%', height: '1px' }} className="bg-danger"></hr>
    </>
  )
}