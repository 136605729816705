import Cookie, { CookieSetOptions } from "universal-cookie";

const cookie = new Cookie();

class CookieService {
  get(key: any) {
    return cookie.get(key);
  }

  set(key: string, value: any, options: CookieSetOptions | undefined = undefined) {
    cookie.set(key, value, options);
  }

  remove(key: any) {
    cookie.remove(key);
  }
}
export default new CookieService();