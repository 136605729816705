import React, { useState } from 'react';
import { Col, Row, Button, Collapse, Card } from 'react-bootstrap';
export function Music(){
  const [open, setOpen] = useState(false);
  
    return (
      <>
        <Row className="m-4">
          <Col className="text-center">
            <Button
              className="font-weight-bold"
              variant="info"
              onClick={() => setOpen(!open)}
              aria-expanded={open}
              >
                NGHE NHẠC
            </Button>
          </Col>
        </Row> 
        <Row>
          <Col>
            <Collapse in={open}>
              <Card className="border-info">
                <Card.Header className="bg-info font-weight-bold text-white text-center">Nghe nhạc</Card.Header>
                <Card.Body className="p-0">
                <iframe width="100%" height="315" src="https://www.youtube.com/embed/MCPu0fSWZZ0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
                </Card.Body>
              </Card>
            </Collapse>
          </Col>
        </Row>
      </>
    )
}