import { faSignInAlt, faSignOutAlt, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { authActions } from './redux/authSlice';
import { Input } from "app/components/partials/form/Input";
import { Link } from 'react-router-dom';
import { Select } from 'app/components/partials/form/Select';
import { updateProfile } from './redux/authApi';
import { Alert } from 'react-bootstrap';

export function Verify() {
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState(false)
  const dispatch = useAppDispatch()

  const handleSubmit = (values: any) => {
    setLoading(true)
    updateProfile(values).then((data) => {
      dispatch(authActions.setUser(data))
    }).catch((error) => {
      setStatus(error.message)
    }).finally(() => {
      setLoading(false)
    })
  }

  const initialValues = {
    name: "",
    referral_code: "",
    server_id: 1,
    avatar_id: 1,
  };

  const Schema = Yup.object().shape({
    name: Yup.string()
      .matches(/^[a-zA-Z0-9]+$/, "Không được chứa ký tự đặc biệt")
      .min(5, "Tối thiểu 5 ký tự")
      .required("Không được bỏ trống"),
    referral_code: Yup.string()
  });

  return (
    <div className="form pt-5">
      <div className="row justify-content-center">
        <h3>Đăng nhập</h3>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={Schema}
            onSubmit={(values) => {
              handleSubmit(values)
            }}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit} style={{ padding: '10px 20px' }}>
                {status && (
                  <Alert variant="danger">{status}</Alert>
                )}
                <div className="form-group">
                  <label>Tên nhân Vật</label>
                  <Field name="name" className="form-control" placeholder="Tên hiển thị" component={Input} autoComplete="off" />
                </div>
                <div className="form-group">
                  <label>Máy chủ</label>
                  <Field as="select" name="server_id" className="form-control">
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((server, key) => (
                      <option key={key} value={server}>Server {server} Sao</option>
                    ))}
                  </Field>
                </div>
                <div className="form-group">
                  <label>Cải trang</label>
                  <Field as="select" name="avatar_id" className="form-control">
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((server, key) => (
                      <option key={key} value={server}>Cải trang {server}</option>
                    ))}
                  </Field>
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-info form-control font-weight-bold text-uppercase" disabled={loading}>
                    <FontAwesomeIcon icon={faSignInAlt} /> Đăng nhập</button>
                </div>
                <div className="form-group text-center">
                  Bạn chưa có tài khoản? {" "}
                  <Link to="/home" type="button" onClick={() => { dispatch(authActions.logout()) }}>
                    <FontAwesomeIcon icon={faSignOutAlt} /> Đăng xuất
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}