export default {
    XSMB_CHOOSE_SERVER        : 'XSMB_CHOOSE_SERVER',
    XSMB_LEAVE_SERVER         : 'XSMB_LEAVE_SERVER',
    XSMB_TURN_INFO            : 'XSMB_TURN_INFO',
    XSMB_TURN_RESULT          : 'XSMB_TURN_RESULT',
    XSMB_USER_PLAY            : 'XSMB_USER_PLAY',
    XSMB_USER_HISTORY         : 'XSMB_USER_HISTORY',
    XSMB_CHAT_PUSH            : 'XSMB_CHAT_PUSH',
    XSMB_CHAT_PULL            : 'XSMB_CHAT_PULL',
    XSMB_HISTORY_PULL         : 'XSMB_HISTORY_PULL',
    XSMB_MINI_RESULT          : 'XSMB_MINI_RESULT',
  }