import { faCoins, faHistory, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLayout } from 'app/components/layout';
import { Input } from 'app/components/partials/form/Input';
import { currency } from 'app/helpers';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { PaginationParams } from 'app/modules/luckyNumber/models/PaginationParams';
import { IWheelHistory } from 'app/modules/wheel/models/IWheelHistory';
import { Field, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Form, FormGroup, Modal, Row, Table } from 'react-bootstrap';
import * as Yup from "yup";
import { fetchGiftCodeHistory, giftCodeStore } from '../redux/homeApi';

export function GiftCode() {
  const { currentUser } = useAppSelector(({ auth }) => auth)
  const [loading, setLoading] = useState(false)
  const [loadingHistory, setLoadingHistory] = useState(false)
  const { response, alertNotice } = useLayout()
  const [personal, setPersonal] = useState(false)
  const [histories, setHistory] = useState<PaginationParams<IWheelHistory>>({
    data: [],
    current_page: 1,
    per_page: 10,
    total: 0,
    last_page: 0,
  })

  const handleSubmit = (values: any, resetForm: any) => {
    setLoading(true)
    giftCodeStore(values).then((data: any) => {
      alertNotice(data.message, 'success')
      resetForm();
      reloadHistory(histories.current_page)
    }).catch((error) => {
      alertNotice(error.message ?? 'Có lỗi xảy ra')
    }).finally(() => {
      setLoading(false)
    })
  }

  const initialValues = {
    code: "",
  };

  const Schema = Yup.object().shape({
    code: Yup.string()
      .matches(/^[a-zA-Z0-9]+$/, "Mã nhận thưởng không hợp lệ")
      .min(5, "Tối thiểu 5 ký tự")
      .required("Không được bỏ trống"),
  });

  useEffect(() => {
    reloadHistory(histories.current_page);
  }, [personal])

  function reloadHistory(page: number) {
    setLoadingHistory(true)
    fetchGiftCodeHistory({ page: page, personal: personal }).then((data: any) => {
      setHistory(data)
    }).finally(() => {
      setLoadingHistory(false)
    })
  }

  return (
    <>
      <h2 className="text-center pt-5">NHẬN QUÀ</h2>
      <hr style={{ width: '15%', height: '1px' }} className="bg-danger mb-3"></hr>
      <Alert variant="info" className="noti-info">
        <b>NHẬP MÃ NHẬN VÀNG</b><br />
        <span>
          Gift code sẽ được phát trong một số <b>sự kiện </b> hoặc xuất hiện trong BOX chat<br />
          Giá trị ngẫu nhiên từ <b>1 triệu tới 10 triệu vàng</b> hoặc được cố định<br />
        </span>
      </Alert>
      <Row>
        <Col lg={5} className="pt-2">
          <Card className="border-info">
            <Card.Header className="bg-info text-light border-info text-center font-weight-bold">
              <FontAwesomeIcon icon={faCoins} /> {" "}
              Số dư: {currency(currentUser?.balance ?? 0)}
            </Card.Header>
            <Card.Body className="border-info">
              <Form.Group className="form-group" controlId="server">
                <Form.Label>Máy chủ</Form.Label>
                <Form.Control type="input" value={`Máy chủ ${currentUser?.server_id ?? 0} sao`} readOnly></Form.Control>
              </Form.Group>
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={Schema}
                onSubmit={(values, { resetForm }) => {
                  handleSubmit(values, resetForm)
                }}
              >
                {({ handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    <FormGroup className="form-group">
                      <Form.Label>Mã nhận thưởng</Form.Label>
                      <Field type="text" name="code" component={Input} placeholder="Nhập mã nhận thưởng"></Field>
                    </FormGroup>

                    {response.message && <Alert variant={response.type}>{response.message}</Alert>}

                    <FormGroup className="form-group">
                      <Button type="submit" disabled={loading} className="btn-block btn-info"><FontAwesomeIcon icon={faPaperPlane} />{" "}Nhận ngay</Button>
                    </FormGroup>
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={7} className="pt-2">
          <Card className="border-info">
            <Card.Header className="bg-info text-light border-info text-center font-weight-bold">
              <FontAwesomeIcon icon={faHistory} /> {" "} Lịch sử nhận thưởng
            </Card.Header>
            <Card.Body className="border-info p-0">
              <Table bordered responsive borderless className="text-center">
                <thead>
                  <tr>
                    <th>Thời gian</th>
                    <th>Nhân vật</th>
                    <th>Phần thưởng</th>
                  </tr>
                </thead>
                <tbody>
                  {loadingHistory ? (<tr className="text-center"><td colSpan={3}>Đang tải...</td></tr>) :
                    histories.data.map((history, key) => (
                      <tr className="table-row" key={key}>
                        <td>{history.created_at}</td>
                        <td>{history.user.name}</td>
                        <td>{currency(history.amount)} {(history.currency?.name)}</td>
                      </tr>
                    ))
                  }
                  {histories.data.length === 0 && !loadingHistory ? <tr className="text-center font-weight-bold text-danger"><td colSpan={3}>Không có dữ liệu</td></tr> : null}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          <div className="row pt-2">
            <div className="col-lg-3 col text-dark ml-auto">
              <select id="isMe" className="form-control" onChange={(e) => setPersonal(e.target.value === '1' ? true : false)}>
                <option value="0">Tất cả</option>
                <option value="1">Mình tôi</option>
              </select>
            </div>
          </div>
        </Col>
      </Row>

    </>
  )
}
