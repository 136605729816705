import { faHistory } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { currency } from "app/helpers";
import { PaginationParams } from "app/modules/luckyNumber/models/PaginationParams";
import React, { useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import Pagination from "react-js-pagination";
import { IWheelHistory } from "../models/IWheelHistory";
import { fetchWheelHistory } from "../redux/wheelApi";

interface Props {
  type: number
}

export function WheelHistory({ type }: Props) {
  const [loading, setLoading] = useState(false)
  const [personal, setPersonal] = useState(false)
  const [histories, setHistory] = useState<PaginationParams<IWheelHistory>>({
    data: [],
    current_page: 1,
    per_page: 10,
    total: 0,
    last_page: 0,
  })

  useEffect(() => {
    reloadHistory(histories.current_page);
  }, [personal])

  function reloadHistory(page: number) {
    setLoading(true)
    fetchWheelHistory({ type: type, page: page, personal: personal }).then((data: any) => {
      setHistory(data)
    }).finally(() => {
      setLoading(false)
    })
  }

  return (
    <>
      <div className="text-center">
        <Card className="border-info">
          <Card.Header className="bg-info text-light text-center font-weight-bold">
            <FontAwesomeIcon icon={faHistory} />
            LỊCH SỬ NHẬN THƯỞNG
          </Card.Header>
          <Card.Body className="p-0">
            <Table borderless>
              <thead>
                <tr>
                  <th>Thời gian</th>
                  <th>Tên nhân vật</th>
                  <th>Phần thưởng</th>
                </tr>
              </thead>
              <tbody className="content-table">
                {loading ? (<tr className="text-center"><td colSpan={3}>Đang tải...</td></tr>) :
                  histories.data.map((history, key) => (
                    <tr className="table-row" key={key}>
                      <td>{history.created_at}</td>
                      <td>{history.user.name}</td>
                      <td>{currency(history.amount)}</td>
                    </tr>
                  ))
                }
                {histories.data.length === 0 && !loading ? <tr className="text-center font-weight-bold text-danger"><td colSpan={3}>Không có dữ liệu</td></tr> : null}
              </tbody>
            </Table>
          </Card.Body>
        </Card>

      </div>
      <div className="row pt-2">
        <div className="col-lg-2 col text-dark mr-auto">
          <select id="isMe" className="form-control" onChange={(e) => setPersonal(e.target.value === '1' ? true : false)}>
            <option value="0">Tất cả</option>
            <option value="1">Mình tôi</option>
          </select>
        </div>
      </div>
    </>
  )
}
