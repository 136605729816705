import { currency } from 'app/helpers'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { SideBar } from 'app/modules/auth/components/SideBar'
import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import Pagination from 'react-js-pagination'
import { ITransaction } from '../models/Transaction'
import { fetchTransactions } from '../redux/paymentApi'
import { paymentActions } from '../redux/paymentSlice'

export default function Transaction() {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)

  const payment = useAppSelector(({ payment }) => payment)

  useEffect(() => {
    reloadHistory(payment.histories.current_page);
  }, [])

  function reloadHistory(page: number) {
    setLoading(true)
    fetchTransactions(page).then((data: any) => {
      dispatch(paymentActions.setTransaction(data))
    }).finally(() => {
      setLoading(false)
    })
  }

  return (
    <>
      <div className="row py-5">
        <SideBar></SideBar>
        <div className="col-lg-9">
          <h4 className="title-text">Lịch sử giao dịch</h4>
          <hr className="title-line" />
          <div className="table-responsive">
            <Table bordered hover>
              <thead className="bg-info text-white">
                <tr>
                  <th>Số tiền</th>
                  <th>Số dư</th>
                  <th>Mô tả</th>
                  <th>Thời gian</th>
                </tr>
              </thead>
              <tbody className="content-table tran-table">
                {loading ? (<tr className="text-center"><td colSpan={4}>Đang tải...</td></tr>) :
                  payment.transactions.data.map((history, key) => (
                    <tr className="table-row" key={key}>
                      <td className={`font-weight-bold text-${history.amount > 0 ? 'success' : 'danger'}`}>{currency(history.amount)}</td>
                      <td>{currency(history.balance)}</td>
                      <td>{history.description}</td>
                      <td>{history.created_at}</td>
                    </tr>
                  ))
                }
                {payment.transactions.data.length === 0 && !loading ? <div className="text-center font-weight-bold pt-5">Không có dữ liệu</div> : null}
              </tbody>
            </Table>
            <div aria-label="Page navigation example">
              <Pagination
                activePage={payment.transactions.current_page}
                itemsCountPerPage={payment.transactions.per_page}
                totalItemsCount={payment.transactions.total}
                pageRangeDisplayed={5}
                onChange={(page: number) => reloadHistory(page)}
                itemClass="page-item"
                linkClass="page-link"
              />
            </div>
          </div>

        </div>
      </div>
    </>
  )
}
