import React, { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FallbackView } from 'app/components/partials'
import { HomeWrapper } from 'app/modules/home/Home'
import { Verify } from 'app/modules/auth/Verify'
import { useAppSelector } from 'app/hooks'
import { SignIn } from 'app/modules/auth/SignIn'
import { SignUp } from 'app/modules/auth/SignUp'
import { PrivateRoute } from './PrivateRoute'
import Profile from 'app/modules/auth/Profile'
import Transaction from 'app/modules/payment/components/Transactions'
import Payment from 'app/modules/payment/Payment'
import Wheel from 'app/modules/wheel/Wheel'
import VipMember from 'app/modules/auth/components/VipMember'
import { Charging } from 'app/modules/payment/Charging'
import { Wallet } from 'app/modules/payment/Wallet'
import { Transfer } from 'app/modules/payment/Transfer'
import FreeWheel from 'app/modules/wheel/FreeWheel'
import { GiftCode } from 'app/modules/home/components/GiftCode'

export function PublicRoutes() {
  const { isAuthorized, currentUser } = useAppSelector(({ auth }) => ({
    isAuthorized: auth.currentUser !== undefined,
    currentUser: auth.currentUser
  }))

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        {(isAuthorized && !currentUser?.name) && (
          <Switch>
            <PrivateRoute path="/verify" component={Verify} />
            <Redirect to="/verify" />
          </Switch>
        )}

        <Redirect from="/" to="/home" exact />
        <Route path="/home" component={HomeWrapper} />
        <PrivateRoute path="/profile" component={Profile} />
        <PrivateRoute path="/transactions" component={Transaction} />
        <PrivateRoute path="/charging" component={Charging} />
        <PrivateRoute path="/payment" component={Payment} />
        {/* <PrivateRoute path="/transfer" component={Transfer} /> */}
        <Redirect from="/withdraw" to="/payment?type=2" />
        <PrivateRoute path="/wheel" component={Wheel} />
        <PrivateRoute path="/free-wheel" component={FreeWheel} />
        <PrivateRoute path="/vip-member" component={VipMember} />
        <PrivateRoute path="/gift-code" component={GiftCode} />
        <PrivateRoute path="/wallet/:type" component={Wallet} />

        {!isAuthorized ? (
          <>
            <Route path="/sign-in" component={SignIn} />
            <Route path="/sign-up" component={SignUp} />
          </>
        ) : (
          <Redirect from='/sign*' to='/' />
        )}

        <Redirect from="/" to="/home" />

      </Switch>
    </Suspense>
  )
}
