import { faGavel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AccountBot } from 'app/modules/payment/models/AccountBot';
import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap';
import { getPkGameAccounts } from '../redux/pkGameApi'

interface PKGameBotProps {
    type: number
}

export default function PKGameBot({type}: PKGameBotProps) {
    const [loading, setLoading] = useState(false);
    const [listBot, setListBot] = useState<AccountBot[]>([]);
    
    useEffect(() => {
        setLoading(true)
        getPkGameAccounts(type).then((data: any) => {
            setListBot(data)
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    return (
        <>
            <div className="card border-info mt-3">
                <div className="card-header bg-info text-light border-seccond text-center font-weight-bold">
                    <FontAwesomeIcon icon={faGavel} /> Vị trí BOT
                </div>
                <div className="table-responsive">
                    <Table bordered striped responsive className="text-center mb-0">
                        <thead>
                            <tr>
                            <th>Nhân vật</th>
                            <th>Địa điểm</th>
                            <th>Trạng thái</th>
                            </tr>
                        </thead>

                        <tbody>
                            {loading ? (<tr className="text-center"><td colSpan={4}>Đang tải...</td></tr>) : null}
                            {listBot.length === 0 && !loading ? (<tr className="text-center"><td colSpan={4}>Không có nhân vật</td></tr>) : null}
                            {listBot.map((item, key) => (
                            <tr key={key}>
                                <td>{item.ingame}</td>
                                <td>Làng KKL {item.zone_id}</td>
                                <td><span className="badge badge-success">Hoạt động</span></td>
                            </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
        </>
    )
}
