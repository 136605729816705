import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap'
import { useLayout } from "app/components/layout";
import { initUserAction } from '../models/IUserAction';
import { amount_string, amount_string_fixed, currency } from 'app/helpers';

export function ActionUser() {
  const { userAction, setUserAction } = useLayout()
  const [currentTab, setCurrentTab] = useState(1)
  return (
    // <Modal show={userAction.id !== -1} onHide={() => setUserAction(initUserAction)}>
    //   <Modal.Header closeButton>
    //     {userAction.name}
    //   </Modal.Header>
    //   <Modal.Body>

    //   </Modal.Body>
    //   <Modal.Footer>
    //     <Button variant="secondary" onClick={() => setUserAction(initUserAction)}>
    //       Đóng
    //     </Button>
    //   </Modal.Footer>
    // </Modal >

    <Modal show={userAction.id !== -1} onHide={() => setUserAction(initUserAction)} className="guild-popup">
      <Modal.Body className="guild-body">
        <>

          <div className="guild-user row">
            <div className="col-4 text-center">
              <img className="guild-avatar" src="/images/avatar/user/3.png" />
            </div>
            <div className="col-8">
              <span className="guild-info">Nhân vật: {userAction.name}</span>
              <span className="guild-info">Thành tích: {amount_string_fixed(userAction.achievement)}</span>
              {/*<span className="guild-info">Bang hội: {userAction.clan_name !== '' ? userAction.clan_name : 'Chưa có'}</span>*/}
            </div>
          </div>

          <div className="guild-header">
            <button className="guild-tab" onClick={() => setCurrentTab(1)}>{currentTab === 1 ? <>Chat<br />bang</> : <>Tin<br />Nhắn</>}</button>
            <button className="guild-tab" onClick={() => setCurrentTab(2)}>Thành<br />viên</button>
          </div>
          <div className="guild-bg">
            <div className="guild-title">
              123
            </div>
          </div>
          {/* <div className="guild-content">
            {guild.currentGuild ? (
              <>
                {currentTab === 1 ? <GuildChat></GuildChat> : null}
                {currentTab === 2 ? <GuildMember></GuildMember> : null}
              </>
            ) : (
              <>
                {currentTab === 1 ? <GuildList></GuildList> : null}
                {currentTab === 2 ? <GuildCreate></GuildCreate> : null}
              </>
            )}
          </div> */}
        </>
      </Modal.Body>
    </Modal>
  )
}
