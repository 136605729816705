export default {
  DICE_CHOOSE_SERVER        : 'DICE_CHOOSE_SERVER',
  DICE_LEAVE_SERVER         : 'DICE_LEAVE_SERVER',
  DICE_TURN_INFO            : 'DICE_TURN_INFO',
  DICE_TURN_RESULT          : 'DICE_TURN_RESULT',
  DICE_USER_PLAY            : 'DICE_USER_PLAY',
  DICE_USER_HISTORY         : 'DICE_USER_HISTORY',
  DICE_CHAT_PUSH            : 'DICE_CHAT_PUSH',
  DICE_CHAT_PULL            : 'DICE_CHAT_PULL',
  DICE_HISTORY_PULL         : 'DICE_HISTORY_PULL',
  DICE_CHEAT_RESULT         : 'DICE_CHEAT_RESULT',
  DICE_BALANCING            : 'DICE_BALANCING',
  DICE_TURN_START           : 'DICE_TURN_START',
  DICE_TURN_FINISH          : 'DICE_TURN_FINISH',
}