import axios from "axios";

export const PK_GAME_STORE_URL = `/pk-game/store`;
export const PK_GAME_ACCOUNTS_URL = `/pk-game/online-accounts`;
export const PK_GAME_HISTORY_URL = `/pk-game/history`;
export const PK_GAME_CANCEL_URL = `/pk-game/cancel`;

export function storePkGameStore({ amount, type, ingame }: any) {
	return axios.post(`${PK_GAME_STORE_URL}`, { amount: amount, type: type, ingame: ingame });
}

export function getPkGameAccounts(type: number) {
	return axios.get(`${PK_GAME_ACCOUNTS_URL}?type=${type}`);
}

export function getPkGameHistory(limit: number, personal: boolean){
	return axios.get(`${PK_GAME_HISTORY_URL}?limit=${limit}&personal=${personal}`);
}


export function storePkGameCancel(order_id: number) {
	return axios.post(`${PK_GAME_CANCEL_URL}`, { order_id: order_id });
}
