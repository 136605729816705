import { useAppDispatch } from "app/hooks";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { authActions } from "../redux/authSlice";
import { getUserByToken } from "../redux/authApi";
import { LayoutSplashScreen } from "app/components/layout";

interface AuthenticatedGuardProps {
	children: JSX.Element;
}

export default function AuthenticatedGuard({
	children,
}: AuthenticatedGuardProps) {
	const didRequest = useRef(false);
	const dispatch = useAppDispatch();
	const [showSplashScreen, setShowSplashScreen] = useState(true);
	const access_token = Boolean(localStorage.getItem("access_token"));

	useEffect(() => {
		const requestUser = async () => {
			try {
				if (!didRequest.current) {
					const user = await getUserByToken();
					dispatch(authActions.setUser(user));
				}
			} catch (error) {
				if (!didRequest.current) {
					dispatch(authActions.logout());
				}
			} finally {
				setShowSplashScreen(false);
			}

			return () => (didRequest.current = true);
		};

		if (access_token) {
			requestUser();
		} else {
			dispatch(authActions.setUser(undefined));
			setShowSplashScreen(false);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
}
