import LuckyNumberCommand from 'app/commands/LuckyNumberCommand';
import SocketCommand from 'app/commands/SocketCommand';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { authActions } from 'app/modules/auth/redux/authSlice';
import React, { createContext, useContext, useEffect } from 'react'
import { io, Socket } from "socket.io-client";

const URL = process.env.REACT_APP_SOCKET_URL;

export interface SocketContextModel {
  socket: Socket,
}

const socket = io(URL ?? '', { // 
  // path: '/lucky-number',
  query: {
    token: localStorage.getItem("access_token") ?? ''
  }
})

const SocketContext = createContext<SocketContextModel>({
  socket: socket,
})

const SocketProvider: React.FC = ({ children }) => {
  const dispatch = useAppDispatch()
  const { isAuthorized } = useAppSelector(({ auth }) => ({
    isAuthorized: auth.currentUser !== undefined,
  }))

  useEffect(() => {
    const token = localStorage.getItem("access_token") ?? '';
    if (socket.io.opts.query?.token !== token) {
      socket.disconnect()
      socket.io.opts.query = {
        token: token
      }
      socket.connect()
      socket.emit(LuckyNumberCommand.LUCKY_CHOOSE_SERVER, {
        server: 1
      });
    }
  }, [isAuthorized])

  useEffect(() => {
    socket.on(SocketCommand.USER_BALANCE, ({ balance }) => {
      dispatch(authActions.setBalance(balance))
    })
  }, [socket])

  return <SocketContext.Provider value={{ socket }}>{children}</SocketContext.Provider>
}

export { SocketContext, SocketProvider }

export function useSocket() {
  return useContext(SocketContext)
}