export const SOCKET_CMD = {
  RECONNECT: 'RECONNECT',
  ONLINE_COUNTER: 'SOCKET_CMD_ONLINE_COUNTER',
  ALERT: 'SOCKET_CMD_ALERT',
  BALANCE: 'SOCKET_CMD_USER_BALANCE',
  STREAK: 'SOCKET_CMD_USER_STREAK',
  REFRESH_RANKING: 'SOCKET_CMD_REFRESH_RANKING',
  REFRESH_CLAN_RANKING: 'SOCKET_CMD_REFRESH_CLAN_RANKING',
  GUILD_MEMBERS: 'SOCKET_CMD_GUILD_MEMBERS',
  GUILD_KICK: 'SOCKET_CMD_GUILD_KICK',
  GUILD_ACTIVITIES: 'SOCKET_CMD_GUILD_ACTIVITIES',
  GUILD_CHAT_PUSH: 'SOCKET_CMD_GUILD_CHAT_PUSH',
  NOTICE: 'SOCKET_CMD_NOTICE',
  
  LUCKY_LIST_SERVER: 'SOCKET_CMD_LUCKY_LIST_SERVER',
  LUCKY_CHOOSE_SERVER: 'SOCKET_CMD_LUCKY_CHOOSE_SERVER',
  LUCKY_TURN_INFO: 'SOCKET_CMD_LUCKY_TURN_INFO',
  LUCKY_TURN_RESULT: 'SOCKET_CMD_LUCKY_TURN_RESULT',
  LUCKY_USER_PLAY: 'SOCKET_CMD_LUCKY_USER_PLAY',
  LUCKY_CHAT_PUSH: 'SOCKET_CMD_LUCKY_CHAT_PUSH',
  LUCKY_CHAT_PULL: 'SOCKET_CMD_LUCKY_CHAT_PULL',
  LUCKY_HISTORY_PULL: 'SOCKET_CMD_LUCKY_HISTORY_PULL',
  
  DICE_CHOOSE_SERVER: 'SOCKET_CMD_DICE_CHOOSE_SERVER',
  DICE_TURN_INFO: 'SOCKET_CMD_DICE_TURN_INFO',
  DICE_TURN_RESULT: 'SOCKET_CMD_DICE_TURN_RESULT',
  DICE_USER_PLAY: 'SOCKET_CMD_DICE_USER_PLAY',
  DICE_USER_HISTORY: 'SOCKET_CMD_DICE_USER_HISTORY',
  DICE_CHAT_PUSH: 'SOCKET_CMD_DICE_CHAT_PUSH',
  DICE_CHAT_PULL: 'SOCKET_CMD_DICE_CHAT_PULL',
  DICE_HISTORY_PULL: 'SOCKET_CMD_DICE_HISTORY_PULL',
  DICE_BALANCING: 'SOCKET_CMD_DICE_BALANCING',
  DICE_TURN_START: 'SOCKET_CMD_DICE_TURN_START',
  DICE_TURN_FINISH: 'SOCKET_CMD_DICE_TURN_FINISH',
}


export const guildLevelExps = [
  1000,
  2000,
  3000,
  5000,
  10000,
  20000,
  35000,
  42000,
  58000,
  82000,
  108000,
  159000,
  195000,
  250000,
  310000,
  420000,
  555000,
  672000,
  852000,
  990000,
  1256000,
  1500000,
  2000000,
  2782000,
  3681200,
  4581200,
  5881200,
  6981200,
  8081200,
]

export const vipLevels = [
  [100000,1000000],
  [500000,7000000],
  [2000000,35000000],
  [5000000,100000000],
  [10000000,225000000],
  [20000000,500000000],
  [50000000,2000000000],
]