import { useAppSelector } from 'app/hooks'
import React from 'react'
import { Redirect, Route } from 'react-router-dom'


export function PrivateRoute({ component: Component, auth, ...rest }: any) {
  const { isAuthorized } = useAppSelector(({ auth }) => ({
    isAuthorized: auth.currentUser !== undefined,
  }))

  return (
    <Route
      {...rest}
      render={(props) => isAuthorized
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/sign-in', state: { from: props.location } }} />}
    />
  )
}
