import { authActions, LoginPayload, PasswordPayload, RegisterPayload } from "./authSlice";
import { fork, take, call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import * as API from './authApi'

function* watchLoginFlow() {
  while (true) {
    const isLoggedIn = Boolean(localStorage.getItem('access_token'))
    if (!isLoggedIn) {
      const action: PayloadAction<LoginPayload> = yield take(authActions.login.type);
      yield fork(handleLogin, action);
    } else {
      yield take(authActions.logout.type);
      yield call(handleLogout)
    }
  }
}

function* handleLogin(action: PayloadAction<LoginPayload>) {
  try {
    const { token, user } = yield call(API.login, action.payload)
    localStorage.setItem('access_token', token)
    yield put(authActions.loginSuccess(user))
  } catch (error: any) {
    let el = document.getElementById('error-login')
    if (el) el.innerHTML = error.message
    yield put(authActions.loginFailed())
  }
}

function* handleLogout() {
  yield put(authActions.logout())
  localStorage.removeItem('access_token');
}

function* handlePassword(action: PayloadAction<PasswordPayload>) {
  let el = document.getElementById('error-password')
  try {
    const { token, user } = yield call(API.changePassword, action.payload)
    localStorage.setItem('access_token', token)
    if (el) el.innerHTML = 'Thay đổi mật khẩu thành công'
    yield put(authActions.passwordDone())
  } catch (error: any) {
    if (el) el.innerHTML = error.message
    yield put(authActions.passwordDone())
  }
}

function* handleRegsiter(action: PayloadAction<RegisterPayload>) {
  try {
    const { token, user } = yield call(API.register, action.payload)
    localStorage.setItem('access_token', token)
    const counter = localStorage.getItem('account_counter')
    localStorage.setItem('account_counter', (counter ? counter : '') + '.')
    yield put(authActions.loginSuccess(user))
  } catch (error: any) {
    let el = document.getElementById('error-register')
    if (el) el.innerHTML = error.message
    yield put(authActions.loginFailed())
  }
}

export default function* authSaga() {
  yield fork(watchLoginFlow);
  yield takeLatest(authActions.register, handleRegsiter)
  yield takeLatest(authActions.password, handlePassword)
}
