import React, { useEffect } from 'react';
import { faSpinner, faHistory } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { currency } from 'app/helpers';
import { MiniResult } from 'app/modules/luckyNumber/components/MiniResult';
import CountUp, { useCountUp } from 'react-countup';
import { Counter } from 'app/components/partials/Counter'
import { useLayout } from 'app/components/layout';
import { Button } from 'react-bootstrap';
import LuckyNumberCommand from '../../../commands/LuckyNumberCommand';
import { luckyNumberActions } from '../redux/luckyNumberSlice';
import { useSocket } from 'app/components/core/Socket';

export function TurnInformation() {
  const luckyNumber = useAppSelector(({ lucky }) => lucky)
  const { betByTypes, jarOfGold } = useAppSelector(({ lucky, jarOfGold }) => ({ betByTypes: lucky.betByTypes, jarOfGold: jarOfGold }))
  const spinner = (<FontAwesomeIcon icon={faSpinner} size="lg" spin />);
  const {setJarOfGoldDisplay} = useLayout()
  const dispatch = useAppDispatch();

  const { socket } = useSocket()
  useEffect(() => {
    socket.on(LuckyNumberCommand.LUCKY_TURN_INFO, (data) => {
      dispatch(luckyNumberActions.setTurnInfo(data))
    })

  }, []);
  return (
    <div className="card border-info">
      <div className="card-header bg-info text-light border-seccond text-center font-weight-bold">
            <FontAwesomeIcon icon={faHistory} /> KẾT QUẢ
      </div>
      <div className="card-body bg-light text-dark">
        <p className="card-text">
          Trạng thái: <span className="font-weight-bold text-info">{luckyNumber.bossState == 'live' ? 'BOSS đang sống, vui lòng chờ BOSS chết để đặt cược' : 'Phiên đặt cược đang mở'}</span><br />
          Mã phiên: <span className="font-weight-bold text-danger">#{luckyNumber.turnId}</span><br />
          Máy chủ: <span className="font-weight-bold text-danger">{luckyNumber.currentServer}</span> sao<br />
          Kết quả trước đó: {luckyNumber.timeLeft > 0 ? <span className={`mini-result-circle rounded-circle text-white text-center bg-${luckyNumber.lastResult % 2 !== 0 ? 'dark' : 'danger'}`}>{luckyNumber.lastResult % 2 !== 0 ? 'B' : 'R'}</span> : spinner }<br />
          Thời gian còn: <span className="text-danger  font-weight-bold">{luckyNumber.timeLeft > 0 ? (luckyNumber.timeLeft + ' giây') : spinner}</span> <br />
          Đỏ: <span className="font-weight-bold text-danger"><Counter target={betByTypes[1]} /></span>
           - Đen: <span id="goldLe" className="font-weight-bold text-primary"><Counter target={betByTypes[2]} /></span><br />
          {/*Tài: <span className="font-weight-bold text-danger"><Counter target={betByTypes[3]} /></span> - Xỉu: <span id="goldXiu" className="font-weight-bold text-primary"><Counter target={betByTypes[4]} /></span><br />*/}
          {/*Nổ hũ: <span className="font-weight-bold text-danger"><Counter target={jarOfGold.amount} /></span> - <span style={{ cursor: 'pointer'}} className="font-weight-bold text-primary" onClick={() => setJarOfGoldDisplay(true)}>Chi Tiết</span>*/}
        </p>

        {/* <Coun tUp
          start={0}
          end={0}
          duration={2}
          separator=","
          decimal=","
        >
          {({ countUpRef, update }) => (
            <div>
              <span ref={countUpRef} />
              <button onClick={() => update(2222)}>Start</button>
            </div>
          )}
        </Coun> */}

        <MiniResult></MiniResult>
      </div>
    </div>
  )
}