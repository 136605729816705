import React from 'react';
import { Message } from '../models/Message';
import { toAbsoluteUrl } from 'app/components/core'
import { useLayout } from 'app/components/layout';
import { EnvelopeUserOpen } from './EnvelopeUserOpen';

interface MessageProps {
  message: Message;
}

export function MessageItem(props: MessageProps) {
  const { setUserAction } = useLayout()
  const message = props.message

  function actionUser(message: Message) {
    // setUserAction({
    //   id: message.id ?? -1,
    //   name: message.name,
    //   avatar: message.avatar,
    //   clan_type: message.clan_type,
    //   vip: message.vip,
    //   clan_name: message.clan_name,
    //   achievement: message.achievement,
    // })
  }

  return (
    <p>
      <span className="font-weight-bold">
        <img src={toAbsoluteUrl(`/images/avatar/${message.type === 1 ? 'user' : 'npc'}/${message.avatar}.png`)} className="avatar-chat" />
        <span className={`name-chat item-${message.type}`} onClick={() => actionUser(message)}>{message.name}</span>
        {message.vip > 0 && (<img src={toAbsoluteUrl(`/images/vip/${message.vip}.png`)} className="vip-chat" />)}
        {message.clan_type > 0 && (<img src={toAbsoluteUrl(`/images/clan/${message.clan_type}.png`)} className="clan-chat" />)}
        {": "}
        <span className={`content-chat ${message.envelope ? 'envelope' : ''}`} dangerouslySetInnerHTML={{ __html: message.message }}></span>
        {message.envelope && (
          <>
            {" "} - <EnvelopeUserOpen envelope={message.envelope} />
          </>
        )}
      </span>
    </p>
  )
}