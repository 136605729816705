import { useSocket } from 'app/components/core/Socket'
import React, { FC, useEffect } from 'react'
import { LuckyNumber } from '../luckyNumber/LuckyNumber'
import { Feature } from './components/Feature'
import { Introduction } from './components/Introduction'
import { useLayout } from 'app/components/layout'
import { History } from 'app/modules/luckyNumber/components/History'
import { RankingPersonal } from '../luckyNumber/components/RankingPersonal'
import { RankingGuild } from '../luckyNumber/components/RankingGuild'
import { Notice } from './components/Notice'
import { Music } from './components/Music'
import { toAbsoluteUrl } from 'app/components/core'
import { ListServerMain } from '../luckyNumber/components/ListServerMain'
import SocketCommand from 'app/commands/SocketCommand'
import { Action } from '../luckyNumber/components/Action'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { PKGameHistory } from '../pkGame/components/PKGameHistory'
import { RankingPayment } from '../luckyNumber/components/RankingPayment'
import { XSMBHistory } from '../xsmb/components/XSMBHistory'
import LuckyNumberCommand from 'app/commands/LuckyNumberCommand'
import { luckyNumberActions } from '../luckyNumber/redux/luckyNumberSlice';
import { toast } from 'react-toastify';


const HomeWrapper: FC = () => {
  const dispatch = useAppDispatch();
  const { currentServer } = useAppSelector(({ lucky }) => ({
    currentServer: lucky.currentServer
  }))

  const { socket } = useSocket()
  const { setResponse } = useLayout()
  const { guildPopup, guildAlertNotice } = useLayout()

  useEffect(() => {
    socket.off(SocketCommand.USER_ALERT)
    if (guildPopup) {
      socket.on(SocketCommand.USER_ALERT, (data) => {
        guildAlertNotice(data.message)
      })
    } else {
      socket.on(SocketCommand.USER_ALERT, (data) => {
        setResponse(data)
      })
    }
  }, [guildPopup])

  useEffect(() => {
    socket.on(LuckyNumberCommand.SERVER_CLOSED, (data) => {
      toast(`🦄Sever ${data} đã đóng cửa. Bạn sẽ được chuyển đến server 24/24 để chơi!`, {
        position: "top-center"
      });

      let server_id = 24;

      window.localStorage.setItem('server_id', server_id.toString())

      socket.emit(LuckyNumberCommand.LUCKY_CHOOSE_SERVER, {
        server: server_id
      });

      dispatch(luckyNumberActions.setCurrentServer(server_id))
    })
  }, [])

  return (
    <>
      <Introduction />
      <Feature />
      <ListServerMain />
      <LuckyNumber></LuckyNumber>
      {/* <Action></Action> */}
      {currentServer <= 24 && <History/>}
      {currentServer === 25 && <PKGameHistory/>}
      {currentServer === 29 && <XSMBHistory/>}
      {/* <RankingGuild></RankingGuild>
      <RankingPersonal></RankingPersonal>
      <RankingPayment></RankingPayment> */}
      <Notice></Notice>
      <Music></Music>

      {/* {(currentServer === 25) ? (
        <>
          <div className="sha-bet-chat">
            <EmbedLive></EmbedLive>
            <ChatDicePublic></ChatDicePublic>
          </div>
          <DiceLiveHistory></DiceLiveHistory>
        </>
      ) : (
        <>
          <div className="sha-bet-chat">
            <LuckyNumber></LuckyNumber>
            <ChatPublic></ChatPublic>
          </div>

          <History></History>
          <RankingPersonal></RankingPersonal>
          <RankingGuild></RankingGuild>
        </>
      )} */}

    </>
  )
}

export { HomeWrapper }
