import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE: any = {
	setting: {},
};

const homeSlice = createSlice({
	name: "home",
	initialState: INITIAL_STATE,
	reducers: {
		setSetting(state, action: PayloadAction<any>) {
			state.setting = action.payload;
		},
	},
});

export const homeActions = homeSlice.actions;

export const homeReducer = homeSlice.reducer;

