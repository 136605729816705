import { useLayout } from "app/components/layout";
import { useAppDispatch, useAppSelector } from "app/hooks";
import React, { useEffect } from "react";
import { PasswordPopup } from "./components/PasswordPopup";
import { SideBar } from "./components/SideBar";
import { authActions } from "./redux/authSlice";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-regular-svg-icons';
import CopyToClipboard from "react-copy-to-clipboard";
import { getUserByToken } from "./redux/authApi";
import { currency, getVipLevel } from "app/helpers";

export default function Profile() {
  const { setPasswordPopup } = useLayout()
  const dispatch = useAppDispatch()
  const { currentUser } = useAppSelector(({ auth }) => ({ currentUser: auth.currentUser }))

  useEffect(() => {
    getUserByToken().then((user) => {
      dispatch(authActions.setUser(user));
    }).catch((error) => {
      dispatch(authActions.logout());
    })
  }, [])



  return (
    <>
      <div className="row py-5">
        <SideBar />
        <div className="col-lg-9">
          <h4 className="title-text">Thông tin tài khoản</h4>
          <hr className="title-line" />
          <table className="table table-striped font-weight-bold mt-5">
            <tbody>
              <tr>
                <th scope="row">ID của bạn: </th>
                <th>#{currentUser?.id}</th>
              </tr>
              <tr>
                <th scope="row">Tên tài khoản: </th>
                <th>{currentUser?.username}</th>
              </tr>
              <tr>
                <th scope="row">Tên hiển thị: </th>
                <th>{currentUser?.name}</th>
              </tr>
              <tr>
                <th scope="row">Máy chủ: </th>
                <th>Máy chủ {currentUser?.server_id}</th>
              </tr>
              <tr>
                <th scope="row">Cấp VIP: </th>
                <th>VIP {getVipLevel(currentUser?.total_buy_gold ?? 0)}</th>
              </tr>
              <tr>
                <th scope="row">Số vàng tài khoản: </th>
                <th className="text-danger">{currency(currentUser?.balance ?? 0)}</th>
              </tr>
              <tr>
                <th scope="row">Mã giới thiệu: </th>
                <th className="text-primary">
                  <CopyToClipboard text={currentUser?.name ?? ''}>
                    <div className="primary" style={{ cursor: 'pointer' }}>
                      {currentUser?.name} {" "} <FontAwesomeIcon icon={faClipboard} size="1x" />
                    </div>
                  </CopyToClipboard>
                </th>
              </tr>
              <tr>
                <th scope="row">Mật khẩu: </th>
                <th className="text-danger">
                  <span style={{ cursor: 'pointer', color: '#0543bf' }} onClick={() => setPasswordPopup(true)}>(****) Đổi mật khẩu</span>
                </th>
              </tr>
              <tr>
                <th scope="row">Đăng xuất: </th>
                <th className="text-primary">
                  <span style={{ cursor: 'pointer', color: '#0543bf' }} onClick={() => dispatch(authActions.logout())}>Thoát</span>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <PasswordPopup></PasswordPopup>
    </>
  )
}