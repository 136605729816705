import { useAppDispatch, useAppSelector } from "app/hooks";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { xsmbActions } from "../redux/xsmbSlice";

interface DiceHistoryProps {
    display: boolean
    setDisplay: (value: boolean) => void
}

export function XSMBGameChooseNumberModal({ display, setDisplay }: DiceHistoryProps) {
  const dispatch = useAppDispatch()
  const { selectedNumbers } = useAppSelector(({ xsmb }) => ({
    selectedNumbers: xsmb.selectedNumbers
  }))

  function addOrRemoveNumber(value: number){
    if(selectedNumbers.indexOf(value) === -1){
      dispatch(xsmbActions.setNumbers([...selectedNumbers, value]))
    }else{
      dispatch(xsmbActions.setNumbers(selectedNumbers.filter(item => item !== value)))
    }
  }

  function resetNumbers(){
    dispatch(xsmbActions.setNumbers([]))
  }

  return (
      <Modal show={display} onHide={() => setDisplay(false)}>
        <Modal.Header closeButton>
          <div className="col text-center">
            <span className="font-weight-bold">Chọn con số may mắn</span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="circle-number-selection">
              {[...Array(100)].map((x, i) =>
                <span key={i} className={`circle-number small-circle ${selectedNumbers.indexOf(i) !== -1 ? 'active' : ''}`} onClick={() => addOrRemoveNumber(i)}>{i < 10 ? `0${i}` : i}</span>
              )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" onClick={() => resetNumbers()} variant="secondary">Reset</Button>
          <Button type="button" onClick={() => setDisplay(false)} variant="success">Xác nhận</Button>
        </Modal.Footer>
      </Modal >
  )
}
