import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Message } from "../models/Message";
import { IServer } from "../models/Server"
import { History } from "../models/History";
import { PaginationParams } from "../models/PaginationParams";

export interface LuckyNumberState {
	currentServer: number;
	listServer: IServer[];
	timeLeft: number;
	turnId: number;
	lastResult: number;
	miniResults: number[];
	messages: Message[];
	betByTypes: number[];
	histories: History[];
	bossState: string;
}

const INITIAL_STATE: LuckyNumberState = {
	currentServer: 1,
	listServer: [],
	timeLeft: 0,
	turnId: 0,
	lastResult: 0,
	miniResults: [],
	messages: [],
	betByTypes: [],
	histories: [],
	bossState: 'live'
};

const luckyNumberSlice = createSlice({
	name: "lucky",
	initialState: INITIAL_STATE,
	reducers: {
		setListServer(state, action: PayloadAction<IServer[]>) {
			state.listServer = action.payload;
		},
		setCurrentServer(state, action: PayloadAction<number>) {
			state.currentServer = action.payload;
		},
		setTurnInfo(state, action: PayloadAction<any>) {
			try {
				state.timeLeft = action.payload.time;
				state.turnId = action.payload.id;
				state.lastResult = action.payload.lastResult;
				state.miniResults = action.payload.miniResults.slice().reverse();
				state.betByTypes = action.payload.types
				state.bossState = action.payload.bossState
			} catch (error) {
				console.error(error);
			}
		},
		setHistory(state, action: PayloadAction<any>) {
			state.histories = action.payload.slice().reverse()
		},
		setMessages(state, action: PayloadAction<Message[]>) {
			state.messages = action.payload;
		},
		setMessage(state, action: PayloadAction<Message>) {
			if (state.messages.length >= 50) state.messages.shift();
			state.messages.push(action.payload);
		},
		setEnvelopeAmountUsed(state, action: PayloadAction<any>){
			state.messages.map(item => {
				if(item.envelope !== undefined && item.envelope.id === action.payload.id){
					item.envelope.used = action.payload.used
				}
			})
		}
	},
});

export const luckyNumberActions = luckyNumberSlice.actions;

export const luckyNumberReducer = luckyNumberSlice.reducer;

