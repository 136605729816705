
import { useEffect, useRef } from 'react';
import { useCountUp } from 'react-countup';

interface Props {
  target: number;
}

export function Counter({ target }: Props) {
  const countUpRef = useRef(null);
  const {
    reset,
    update,
    start,

  } = useCountUp({
    ref: countUpRef,
    start: 0,
    end: target,
    duration: 1,
    separator: ','
  });

  useEffect(() => {
    start()
  }, [])

  useEffect(() => {
    if (target === 0) reset()
    else update(target)
  }, [target])

  return (
    <span ref={countUpRef} />
  );
}
