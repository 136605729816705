import { combineReducers } from "@reduxjs/toolkit";
import { authReducer } from "app/modules/auth/redux/authSlice";
import { diceReducer } from "app/modules/dice/redux/diceSlice";
import { diceLiveReducer } from "app/modules/dice/redux/diceLiveSlice";
import { guildReducer } from "app/modules/guild/redux/guildSlice";
import { homeReducer } from "app/modules/home/redux/homeSlice";
import { luckyNumberReducer } from "app/modules/luckyNumber/redux/luckyNumberSlice";
import { paymentReducer } from "app/modules/payment/redux/paymentSlice";
import { pickMeReducer } from "app/modules/pickMe/redux/pickMeSlice";
import { jarOfGoldReducer } from "app/modules/jarOfGold/redux/jarOfGoldSlice";
import { xsmbReducer } from "app/modules/xsmb/redux/xsmbSlice";

export const rootReducer = combineReducers({
	auth: authReducer,
	lucky: luckyNumberReducer,
	payment: paymentReducer,
	home: homeReducer,
	guild: guildReducer,
	dice: diceReducer,
	diceLive: diceLiveReducer,
	pickMe: pickMeReducer,
	jarOfGold: jarOfGoldReducer,
	xsmb: xsmbReducer
})