import { toAbsoluteUrl } from "../core";

export function FallbackView() {
  return (
    <div className="fallback-screen">
      <img src={toAbsoluteUrl('images/logo.png')} alt='Start logo' /><br></br>
      <span>Loading...</span>
    </div>
  )
}
