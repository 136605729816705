import { createGlobalStyle } from 'styled-components';

  export const lightTheme = {
    name: 'light',
    background: '#f8f9fa !important',
    text: '#363537',
    toggleBorder: '#FFF',
  }
  
  export const darkTheme = {
    name: 'dark',
    background: '#32363a !important',
    text: '#FAFAFA !important',
    toggleBorder: '#6B8096',
  }


  export const GlobalStyles = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  body {
    align-items: center;
    background: ${({ theme }: any) => theme.background};
    color: ${({ theme }) => theme.text};
    transition: all 0.25s linear;
  }

  .bg-light, .modal-content, .card {
    background: ${({ theme }: any) => theme.background};
  }

  h1,h2,h3,h4,h5,h6,.text-dark,a , .table{
    color: ${({ theme }) => theme.text};
  }

`