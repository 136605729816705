import { AxiosRequestConfig, AxiosInstance, AxiosResponse, AxiosError } from 'axios';
import { authActions } from 'app/modules/auth/redux/authSlice';
import Swal from 'sweetalert2'

export default function setupAxios(axios: AxiosInstance, store: any){
    axios.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      config.baseURL = process.env.REACT_APP_API_URL;
      config.withCredentials = true;

      const access_token = localStorage.getItem('access_token');

      if (access_token) {
        config.headers.Authorization = `Bearer ${access_token}`;
      }
      
      return config;
    },
    (error: AxiosError) => Promise.reject(error)
  );

  axios.interceptors.response.use(
    (response: AxiosResponse) => {
      if(response && response.data){
        if(response.data.balance){
          store.dispatch(authActions.setBalance(response.data.balance))
        }
        if(response.data.diamond){
          store.dispatch(authActions.setDiamond(response.data.diamond))
        }
        return response.data;
      }
      return response;
    },
    (error: AxiosError<AxiosResponse>) => {
      if(error.response && error.response.status === 401){
        store.dispatch(authActions.logout())
      }else if(error.response && error.response.status === 429){
        Swal.fire('Có vẻ như bạn đang thao tác quá nhanh, vui lòng nghỉ một chút nhé')
      }
      if(error.response && error.response.data){
        return Promise.reject(error.response.data);
      }
      return Promise.reject(error.response)
    }
  )
}