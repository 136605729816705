import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginationParams } from "app/modules/luckyNumber/models/PaginationParams";
import { IGuild } from "../models/IGuild";
import { IGuildActivity } from "../models/IGuildActivity";
import { IGuildMember } from "../models/IGuildMember";
import { IGuildType } from "../models/IGuildType";

export interface guildState {
	nameSearch: string;
	loading: boolean;
	currentGuild?: IGuild;
	guildList: IGuild[];
	guildTypes: IGuildType[];
	refreshGuild: number;
	memberList: IGuildMember[];
}

const INITIAL_STATE: guildState = {
	nameSearch: '',
	loading: false,
	guildTypes: [],
	guildList: [],
	refreshGuild: 0,
	memberList: []
};

const guildSlice = createSlice({
	name: "guild",
	initialState: INITIAL_STATE,
	reducers: {
		setLoading(state, action: PayloadAction<boolean>) { 
			state.loading = action.payload
		},
		setGuild(state, action: PayloadAction<IGuild | undefined>) { 
			state.currentGuild = action.payload
		},
		setGuildList(state, action: PayloadAction<IGuild[]>) { 
			state.guildList = action.payload
		},
		setGuildType(state, action: PayloadAction<IGuildType[]>) { 
			state.guildTypes = action.payload
		},
		setGuildMembers(state, action: PayloadAction<IGuildMember[]>) { 
			state.memberList = action.payload
		},
		refreshGuild(state){
			state.refreshGuild++
		},
		setNameSearch(state, action: PayloadAction<string>){
			state.nameSearch = action.payload
		}
	},
});

export const guildActions = guildSlice.actions;

export const guildReducer = guildSlice.reducer;

