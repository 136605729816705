
import React, { FC } from 'react'
import { Switch } from 'react-router-dom'
import { MasterLayout } from 'app/components/layout'
import { PublicRoutes } from './PublicRoutes'
import { SocketProvider } from 'app/components/core/Socket'

const Routes: FC = () => {

  return (
    <Switch>
      <SocketProvider>
        <MasterLayout>
          <PublicRoutes />
        </MasterLayout>
      </SocketProvider>
    </Switch>
  )
}

export { Routes }
