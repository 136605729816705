import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLayout } from "app/components/layout";
import { useAppSelector } from "app/hooks";
import { GameGuide } from "app/modules/luckyNumber/components/GameGuide";
import React, { useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

export function Feature() {
  const { setting, currentServer } = useAppSelector(({ home, lucky }) => ({ setting: home.setting, currentServer: lucky.currentServer }))
  const [showGuide, setShowGuide] = useState(false);
  const [showPKGuide, setShowPKGuide] = useState(false);
  const [showPKGuide2, setShowPKGuide2] = useState(false);
  const {darkMode, setDarkMode} = useLayout()
  const isDarkMode = localStorage.getItem('darkMode') === 'true'

  function toggleDarkMode() {
    setDarkMode(!isDarkMode)
    window.localStorage.setItem('darkMode', !isDarkMode ? 'true' : 'false')
  }

  return (
    <>
      <div className="text-center pt-3">
        <Dropdown style={{ display: 'contents' }}>
          <Dropdown.Toggle variant="danger" id="dropdown-feature" className="m-1">
            Chức năng
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {/*<Link className="dropdown-item" to="/charging">Mua vàng</Link>*/}
            {/*<Link className="dropdown-item" to="/payment">Nạp vàng</Link>*/}
            {/*<Link className="dropdown-item" to="/withdraw">Rút vàng</Link>*/}
            {/*<Link className="dropdown-item" to="/wheel">Vòng quay may mắn</Link>*/}
            {/*<Link className="dropdown-item" to="/free-wheel">Vòng quay hằng ngày</Link>*/}
            {/*<Link className="dropdown-item" to="/gift-code">Nhập Gift Code</Link>*/}
            {/*<div className="dropdown-divider"></div>*/}
            <Link className="dropdown-item" to="/profile">Thông tin cá nhân</Link>
            {/* <Link className="dropdown-item" to="/transfer">Chuyển vàng</Link> */}
          </Dropdown.Menu>
        </Dropdown>
        {currentServer <= 24 && (
          <Button variant="primary" className="m-1" onClick={() => setShowGuide(true)}>
            H.Dẫn CLTX
          </Button>
        )}
        {currentServer === 25 && (
          <Button variant="primary" className="m-1" onClick={() => setShowPKGuide(true)}>
            H.Dẫn CLPK1
          </Button>
        )}
        {currentServer === 26 && (
          <Button variant="primary" className="m-1" onClick={() => setShowPKGuide2(true)}>
            H.Dẫn CLPK2
          </Button>
        )}
        <GameGuide content={setting.guide_content} display={showGuide} setDisplay={setShowGuide} />
        <GameGuide content={setting.pk_game_guide_content} display={showPKGuide} setDisplay={setShowPKGuide} />
        <GameGuide content={setting.pk_game_guide_content2} display={showPKGuide2} setDisplay={setShowPKGuide2} />
        {/* <a className="btn btn-primary m-1" href={"https://new.nro88.web"} target="_blank">
          Cấu hình cao
        </a> */}
        <a className="btn btn-primary m-1" href={setting.group} target="_blank">
          <FontAwesomeIcon icon={faFacebook} /> Group Facebook
        </a>
      </div>

        <div className="custom-control custom-switch text-center mt-2">
            <input checked={isDarkMode} type="checkbox" id="custom-switch" className="custom-control-input" onChange={(e) => toggleDarkMode()}/>
            <label htmlFor="custom-switch" className="custom-control-label">Giao diện tối</label>
        </div>

    </>
  )
}
