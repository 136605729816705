export interface IUserAction {
  id: number;
  name: string;
  avatar: number;
  clan_type: number
  clan_name: string
  achievement: number
  vip: number
}

export const initUserAction: IUserAction = {
  id: -1,
  name: '',
  avatar: 1,
  clan_type: -1,
  vip: 0,
  clan_name: '',
  achievement: 0
}