import { useAppSelector } from 'app/hooks';
import React from 'react';
import { Streak } from './Streak';

export function MiniResult() {
  const luckyNumber = useAppSelector(({ lucky }) => lucky)

  return (
    <div>
      <p className="mb-2">
        Đỏ Đen: {" "}
        {luckyNumber.miniResults.map((result: number, key) =>
          (<span key={key} className={`mini-result-circle rounded-circle text-white text-center bg-${result % 2 !== 0 ? 'dark' : 'danger'}`}>{result % 2 !== 0 ? 'B' : 'R'}</span>)
        )}
      </p>

      {/*<div className="resul-tx">*/}
      {/*  TX: {" "}*/}
      {/*  {luckyNumber.miniResults.map((result: number, key) =>*/}
      {/*    (<span key={key} className={`mini-result-circle rounded-circle text-white text-center bg-${result < 50 ? 'danger' : 'success'}`}>{result < 50 ? 'X' : 'T'}</span>)*/}
      {/*  )}*/}
      {/*</div>*/}

    </div>);
}