
import LuckyMoneyCommand from 'app/commands/LuckyMoneyCommand';
import { useSocket } from 'app/components/core/Socket';
import React, { useState } from 'react';

interface Props {
  envelope: {
    id: number;
    amount: number
    envelope: number
    used: number
    status: 0
  }
}

export function EnvelopeUserOpen({ envelope }: Props) {
  const { socket } = useSocket()

  function openEnvelope(id: number) {
    if (envelope.used < envelope.envelope) {
      socket.emit(LuckyMoneyCommand.LUCKY_MONEY_USER_OPEN, { id: id })
    }
  }

  return (
    <>
      <button className="btn btn-sm btn-danger" onClick={() => openEnvelope(envelope.id)}>{`${envelope.used}/${envelope.envelope}`} bao</button>
    </>
  )
}