import { useAppDispatch } from "app/hooks";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { getSetting } from 'app/modules/home/redux/homeApi'
import { homeActions } from 'app/modules/home/redux/homeSlice'
import { LayoutSplashScreen } from "app/components/layout";

interface AuthenticatedGuardProps {
	children: JSX.Element;
}

export default function AuthenticatedGuard({
	children,
}: AuthenticatedGuardProps) {
	const didRequest = useRef(false);
	const dispatch = useAppDispatch();
	const [showSplashScreen, setShowSplashScreen] = useState(true);

	useEffect(() => {
		const requestSetting = async () => {
      getSetting().then(data => {
        dispatch(homeActions.setSetting(data))
      }).finally(() => {
        setShowSplashScreen(false);
      })
			return () => (didRequest.current = true);
		};

		requestSetting();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
}
