import { useSocket } from 'app/components/core/Socket';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import React, { useEffect, useRef } from 'react';
import { luckyNumberActions } from '../redux/luckyNumberSlice';
import { MessageItem } from './MessageItem';
import MessengerCommand from "app/commands/MessengerCommand";
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionUser } from './ActionUser';
import { LuckyMoney } from './LuckyMoney'
import { MenuChat } from './MenuChat'


export function ChatPublic() {
  const { socket } = useSocket()
  const dispatch = useAppDispatch();
  const messageRef = useRef<HTMLInputElement | null>(null);
  const scrollRef = useRef<any>(null);
  const { messages } = useAppSelector(({ lucky }) => ({
    messages: lucky.messages,
  }))
  const { currentServer } = useAppSelector(({ lucky }) => lucky)

  const scrollToBottom = () => {
    const elm = document.querySelector('.items');
    if (elm) {
      elm.scrollTop = elm.scrollHeight
    }
  }

  useEffect(scrollToBottom, [messages]);

  useEffect(() => {
    socket.on(MessengerCommand.MESSENGER_REFRESH, (data) => {
      if (data.room.indexOf('LUCKY_NUMBER') !== -1) {
        dispatch(luckyNumberActions.setMessages(data.messages))
      }
    })
    socket.on(MessengerCommand.MESSENGER_PUSH, (data) => {
      if (data.room.indexOf('LUCKY_NUMBER') !== -1) {
        dispatch(luckyNumberActions.setMessage(data.message))
      }
    })
    return () => {
      socket.off(MessengerCommand.MESSENGER_REFRESH)
      socket.off(MessengerCommand.MESSENGER_PUSH)
    }
  }, [socket])

  useEffect(() => {
    socket.emit(MessengerCommand.MESSENGER_REFRESH, { room: `LUCKY_NUMBER:${currentServer}` })
  }, [currentServer])

  function sendMessage(e: any) {
    e.preventDefault()
    const message = messageRef.current?.value
    socket.emit(MessengerCommand.MESSENGER_PUSH, { room: `LUCKY_NUMBER:${currentServer}`, message: message })
    if (messageRef.current) {
      messageRef.current.value = ""
    }
  }

  return (
    <div className="card border-info" style={{ maxHeight: '704px' , height: '100%' }}>
      <div className="card-header bg-info text-light text-center font-weight-bold">
        <FontAwesomeIcon icon={faComment} /> BOX CHAT
      </div>
      <div className="card-body text-second bg-light text-dark chat-box items">
        {messages.map((message, key) => {
          return (
            <div key={key} className="item" ref={scrollRef}>
              <MessageItem message={message}></MessageItem>
            </div>
          );
        })}
      </div>
      <div className="card-footer bg-light text-dark p-0 border-none">
        <LuckyMoney />
        <MenuChat />
        <form onSubmit={sendMessage}>
          <div className="btn-group btn-group-toggle p-0 m-1" style={{ width: '99%' }}>
            <input type="text" id="chatContent" className="form-control mb-0" placeholder="Nhập nội dung chat" ref={messageRef} />
            <button className="btn btn-primary form-control font-weight" id="chatButton" style={{ maxWidth: '20%' }}>Gửi</button>
          </div>
        </form >
      </div >
      <ActionUser></ActionUser>
    </div >
  )
}