import { faGavel, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import XSMBCommand from "app/commands/XSMBCommand";
import { useSocket } from "app/components/core/Socket";
import { useAppDispatch, useAppSelector } from "app/hooks";
import React, { useEffect, useState } from "react";
import { xsmbActions } from "../redux/xsmbSlice";
import { ResultDetailModal } from "./XSMBResultDetailModal";

const spinner = (<FontAwesomeIcon icon={faSpinner} size="lg" spin />);

interface XSMBResultProps {
  results: number[] | string[];
}

export function XSMBResultTable({results} : XSMBResultProps){
  return (
    <div className="block-main-content">
      <table className="table table-bordered table-striped table-xsmb mb-0">
        <tbody>
          <tr>
            <td>G.ĐB</td>
            <td className="text-center"><span className="special-prize-lg div-horizontal">
              {results[26] ?? spinner}
            </span>
            </td>
          </tr>
          <tr>
            <td>G.1</td>
            <td className="text-center">
              <span className="number-black-bold div-horizontal">
                {results[0] ?? spinner}
              </span>
            </td>
          </tr>
          <tr>
            <td>G.2</td>
            <td>
              <span className="col-xs-6 number-black-bold div-horizontal">
                {results[1] ?? spinner}
              </span>
              <span className="col-xs-6 number-black-bold div-horizontal">
                {results[2] ?? spinner}
              </span>
            </td>
          </tr>
          <tr>
            <td>G.3</td>
            <td>
              {[...Array(6)].map((x, i) =>
              <span key={i} className={`col-xs-4 number-black-bold div-horizontal ${i < 3 ? 'mb-2': ''}`}>{results[i+3] ?? spinner}</span>
              )}
            </td>
          </tr>
          <tr>
            <td>G.4</td>
            <td>
              {[...Array(4)].map((x, i) =>
                <span key={i} className="col-xs-6 col-md-3 number-black-bold div-horizontal">{results[i+9] ?? spinner}</span>
              )}
            </td>
          </tr>
          <tr>
            <td>G.5</td>
            <td>
              {[...Array(6)].map((x, i) =>
              <span key={i} className={`col-xs-4 number-black-bold div-horizontal ${i < 3 ? 'mb-2': ''}`}>{results[i+13] ?? spinner}</span>
              )}
            </td>
          </tr>
          <tr>
            <td>G.6</td>
            <td>
              {[...Array(3)].map((x, i) =>
              <span key={i} className="col-xs-4 number-black-bold div-horizontal">{results[i+19] ?? spinner}</span>
              )}
            </td>
          </tr>
          <tr>
            <td>G.7</td>
            <td>
              {[...Array(4)].map((x, i) =>
              <span key={i} className="col-xs-3 special-prize-sm div-horizontal">{results[i+22] ?? spinner}</span>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export function XSMBResultCard(){
  const dispatch = useAppDispatch();
  const { socket } = useSocket()
  const xsmb = useAppSelector(({ xsmb }) => xsmb)
  const [detailModal, setDetailModal] = useState(false)
  const [numbers, setNumbers] = useState<string[]>([])

  useEffect(() => {

    socket.emit(XSMBCommand.XSMB_CHOOSE_SERVER)
    socket.emit(XSMBCommand.XSMB_MINI_RESULT)

    socket.on(XSMBCommand.XSMB_TURN_INFO, (data) => {
      dispatch(xsmbActions.setTurnInfo(data))
    })

    socket.on(XSMBCommand.XSMB_MINI_RESULT, (data) => {
      dispatch(xsmbActions.setMiniResult(data))
    })

    return () => {
      socket.emit(XSMBCommand.XSMB_LEAVE_SERVER)
      socket.off(XSMBCommand.XSMB_TURN_INFO)
      socket.off(XSMBCommand.XSMB_MINI_RESULT)
      socket.io.off("reconnect")
    }

  }, [socket])

  const handleShowDetail = (data: string[]) => {
    setNumbers(data)
    setDetailModal(true)
  }

  return (
      <div className="card border-info ">
          <div className="card-header bg-info text-light border-seccond text-center font-weight-bold">
              <FontAwesomeIcon icon={faGavel} /> {xsmb.timeLeft > 0 ? `Quay sau ${xsmb.timeLeft} giây nữa` : spinner}
          </div>
          <div className="card-body bg-light text-dark p-0">
              <XSMBResultTable results={xsmb.resultNumbers}/>
          </div>
          <div className="circle-number-selection my-1">
              {xsmb.miniResult.map((item, i) =>
                <span key={i} className={`circle-number small-circle`} onClick={() => handleShowDetail(item)} style={{ fontSize: "16px" }}>{item[item.length-1].toString().substr(-2)}</span>
              )}
          </div>
          <ResultDetailModal display={detailModal} setDisplay={setDetailModal} numbers={numbers}/>
      </div>
  )
}
