import { vipLevels } from "app/contants";
import { amount_string, currency } from "app/helpers";
import { Button, Modal } from "react-bootstrap";

interface IProps {
  display: boolean
  setDisplay: (value: boolean) => void
}

export function VipPopup({ display, setDisplay }: IProps) {
  return (
    <Modal show={display} onHide={() => setDisplay(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Khuyến mãi</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Mua vàng tích tiền lên <span className="text-danger font-weight-bold">thành viên VIP</span>
        <table className="table table-sm text-center mt-1">
          <tbody><tr className="bg bg-danger text-white">
            <th>VIP</th>
            <th>Số tiền</th>
            <th>Số vàng nhận</th>
          </tr>
            {vipLevels.map((item, index) => (
              <tr key={index}>
                <td>VIP {index + 1}</td>
                <td>{currency(item[0])}</td>
                <td>{amount_string(item[1])} / 1 ngày</td>
              </tr>
            ))}
          </tbody>
        </table>
        <p>
          <span className="text-danger">Lưu ý:</span><br />
          - VIP tương ứng với tổng số tiền bạn nạp trong 30 ngày gần nhất!<br />
          - Áp dụng cho nạp thẻ cào và ví<br />
          - Khi lên VIP bạn sẽ trông ngầu hơn khi chém gió và đặc biệt rất dễ tán gái nhé<br />
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setDisplay(false)}>
          Đóng
        </Button>
      </Modal.Footer>
    </Modal>
  )
}