import { useSocket } from 'app/components/core/Socket';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import React, { useEffect, useRef } from 'react';
import { diceActions } from '../redux/diceSlice';
import { MessageItem } from 'app/modules/luckyNumber/components/MessageItem';
import MessengerCommand from 'app/commands/MessengerCommand';
import { LuckyMoney } from 'app/modules/luckyNumber/components/LuckyMoney';
import { MenuChat } from 'app/modules/luckyNumber/components/MenuChat';
import { ActionUser } from 'app/modules/luckyNumber/components/ActionUser';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function ChatDicePublic() {
  const { socket } = useSocket()
  const dispatch = useAppDispatch();
  const messageRef = useRef<HTMLInputElement | null>(null);
  const scrollRef = useRef<any>(null);
  const { messages } = useAppSelector(({ dice }) => ({
    messages: dice.messages,
  }))
  const { currentServer } = useAppSelector(({ lucky }) => lucky)

  const scrollToBottom = () => {
    const elm = document.querySelector('.items');
    if(elm){
      elm.scrollTop = elm.scrollHeight
    }
  }

  useEffect(scrollToBottom, [messages]);

  useEffect(() => {
    socket.on(MessengerCommand.MESSENGER_REFRESH, (data) => {
      console.log(data)
      if (data.room.indexOf('ROLL_DICE') !== -1) {
        dispatch(diceActions.setMessages(data.messages))
      }
    })
    socket.on(MessengerCommand.MESSENGER_PUSH, (data) => {
      console.log(data)
      if (data.room.indexOf('ROLL_DICE') !== -1) {
        dispatch(diceActions.setMessage(data.message))
      }
    })
    return () => {
      socket.off(MessengerCommand.MESSENGER_REFRESH)
      socket.off(MessengerCommand.MESSENGER_PUSH)
    }
  }, [socket])

  function sendMessage(e: any) {
    e.preventDefault()
    const message = messageRef.current?.value
    socket.emit(MessengerCommand.MESSENGER_PUSH, { room: `ROLL_DICE:${currentServer - 26}`, message: message })
    if(messageRef.current){
      messageRef.current.value = ""
    }
  }

  

  
  return (
    <div className="card border-info" style={{ height: '100%' }}>
      <div className="card-header bg-info text-light text-center font-weight-bold">
        <FontAwesomeIcon icon={faComment} /> BOX CHAT
      </div>
      <div className="card-body text-second bg-light text-dark chat-box items">
        {messages.map((message, key) => {
          return (
            <div key={key} className="item" ref={scrollRef}>
              <MessageItem message={message}></MessageItem>
            </div>
          );
        })}
      </div>
      <div className="card-footer bg-light text-dark p-0 border-none">
        <LuckyMoney />
        <MenuChat />
        <form onSubmit={sendMessage}>
          <div className="btn-group btn-group-toggle p-0 m-1" style={{ width: '99%' }}>
            <input type="text" id="chatContent" className="form-control mb-0" placeholder="Nhập nội dung chat" ref={messageRef} />
            <button className="btn btn-primary form-control font-weight" id="chatButton" style={{ maxWidth: '20%' }}>Gửi</button>
          </div>
        </form >
      </div >
      <ActionUser></ActionUser>
    </div >
  )
}