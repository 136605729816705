import { toAbsoluteUrl } from 'app/components/core';
import { useLayout } from 'app/components/layout';
import { vipLevels } from 'app/contants';
import { amount_string, currency, getVipLevel } from 'app/helpers';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { receiveVipGift } from '../redux/authApi';
import { authActions } from '../redux/authSlice';
import { SideBar } from './SideBar';

export default function VipMember() {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const { currentUser } = useAppSelector(({ auth }) => ({ currentUser: auth.currentUser }))
  const currentLevel = getVipLevel(currentUser?.total_buy_gold ?? 0)

  function handleVip() {
    if (loading) return
    setLoading(true)
    receiveVipGift().then((data: any) => {
      Swal.fire({
        icon: 'success',
        title: 'Thành công',
        text: data.message ?? 'Có lỗi xảy ra',
      })
      dispatch(authActions.setBalance(data.balance ?? 0))
    }).catch((error) => {
      Swal.fire({
        icon: 'error',
        title: 'Xin lỗi',
        text: error.message ?? 'Có lỗi xảy ra',
      })
    }).finally(() => setLoading(false))
  }
  return (
    <>
      <div className="row py-5">
        <SideBar></SideBar>
        <div className="col-lg-9">
          <h4 className="title-text">Thông tin VIP</h4>
          <hr className="title-line" />
          <div className="b-c-content text-center font-weight-bold">
            {currentLevel > 0 ? (
              <>Bạn đang là <span className="text-danger">thành viên VIP</span> {currentLevel > 0 && (<img src={toAbsoluteUrl(`/images/vip/${currentLevel}.png`)} className="vip-chat" />)}</>
            ) : (
              <>Bạn chưa phải <span className="text-danger">thành viên VIP</span></>
            )}

            <div className="pt-3">
              <button className="btn btn-danger font-weight-bold" onClick={handleVip} disabled={loading}>{loading ? 'ĐANG NHẬN...' : 'NHẬN VÀNG VIP'}</button>
            </div>
            <div className="pt-3">
              {currentLevel < vipLevels.length ? (
                <>
                  Bạn cần nạp thêm <span className="text-danger">{currency(vipLevels[currentLevel][0] - (currentUser?.total_buy_gold ?? 0))}</span> nữa để có thể lên VIP {currentLevel + 1}
                </>
              ) : (
                <>
                  Bạn đã đạt đến cấp độ tối đa của VIP MEMBER<br />Chúng tôi trân trọng gửi lời cám ơn đến bạn
                </>
              )}
              {currentLevel >= 2 && (
                <>
                  <br />
                  Tham gia cộng đồng VIP <a target="_blank" href="https://zalo.me/g/yigoeg181">tại đây</a>
                </>
              )}
              <br />
              <br />Dưới đây là bảng giá VIP:
            </div>

            <table className="table table-sm text-center mt-1">
              <tbody><tr className="bg bg-danger text-white">
                <th>VIP</th>
                <th>Số tiền</th>
                <th>Số vàng nhận</th>
              </tr>
                {vipLevels.map((item, index) => (
                  <tr key={index} className={`text-${currentLevel === index + 1 ? 'danger' : 'dark'}`}>
                    <td>VIP {index + 1}</td>
                    <td>{currency(item[0])}</td>
                    <td>{amount_string(item[1])} / 1 ngày</td>
                  </tr>
                ))}
              </tbody>
            </table>

          </div>
        </div>
      </div>


    </>
  )
}
