import React from "react";
import { useLayout } from "app/components/layout";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { authActions } from '../redux/authSlice';
import { Input } from "app/components/partials/form/Input";
import { Button, Modal } from "react-bootstrap";

export function PasswordPopup() {
  const { passwordPopup, setPasswordPopup } = useLayout()
  const dispatch = useAppDispatch();
  const isLogging = useAppSelector(state => state.auth.logging);

  const handleSubmit = (values: any) => {
    dispatch(
      authActions.password(values)
    )
  }

  const initialValues = {
    currentPassword: "",
    newPassword: "",
    password_confirmation: "",
  };

  const Schema = Yup.object().shape({
    currentPassword: Yup.string()
      .required("Không được bỏ trống"),
    newPassword: Yup.string()
      .min(6, "Tối thiểu 6 ký tự")
      .required("Không được bỏ trống"),
    password_confirmation: Yup.string()
      .min(6, "Tối thiểu 6 ký tự")
      .required("Không được bỏ trống")
      .when("newPassword", {
        is: (val: any) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("newPassword")],
          "Mật khẩu nhập lại không khớp"
        ),
      }),
  });

  return (
    <Modal show={passwordPopup} onHide={() => setPasswordPopup(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Đổi mật khẩu</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={Schema}
          onSubmit={(values) => {
            handleSubmit(values)
          }}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit} id="password-form">
              <div className="text-danger text-center font-weight-bold" id="error-password"></div>
              <div className="regis-form">
                <div className="text-danger text-center pb-4"></div>
                <div className="form-group">
                  <label htmlFor="">Mật khẩu hiện tại</label>
                  <Field name="currentPassword" placeholder="Mật khẩu hiện tại" component={Input} autoComplete="off" />
                </div>
                <div className="form-group">
                  <label htmlFor="">Mật khẩu mới</label>
                  <Field name="newPassword" placeholder="Mật khẩu mới" type="password" component={Input} autoComplete="off" />
                </div>
                <div className="form-group">
                  <label htmlFor="">Nhập lại mật khẩu</label>
                  <Field name="password_confirmation" placeholder="Nhập lại mật khẩu" type="password" component={Input} autoComplete="off" />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setPasswordPopup(false)}>
          Đóng
        </Button>
        <Button type="submit" variant="info" form="password-form" disabled={isLogging}>
          Lưu thay đổi
        </Button>
      </Modal.Footer>
    </Modal>
  )
}