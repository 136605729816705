import { useAppDispatch, useAppSelector } from 'app/hooks';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { wheelPlay } from './redux/wheelApi';
import { faGem } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { authActions } from '../auth/redux/authSlice';
import { WheelHistory } from './components/IWheelHistory';
import { toAbsoluteUrl } from 'app/components/core';
import { Alert } from 'react-bootstrap';
import Swal from 'sweetalert2';

export default function Wheel() {
  const dispatch = useAppDispatch()
  const { currentUser } = useAppSelector(({ auth }) => auth)
  const [transform, setTransform] = useState(0)
  const [loading, setLoading] = useState(false)
  const wheelRef = useRef(null)

  function startWheel() {
    if (loading) return
    setLoading(true)
    wheelPlay({ type: 1 }).then((data: any) => {
      rotate(data.position)
      setTimeout(() => {
        dispatch(authActions.setBalance(data.balance2))
        Swal.fire({
          icon: 'success',
          title: 'Chúc mừng',
          text: data.message ?? 'Có lỗi xảy ra',
        })
        setLoading(false)
      }, 10000)
    }).catch((error: any) => {
      Swal.fire({
        icon: 'error',
        title: 'Xin lỗi',
        text: error.message ?? 'Có lỗi xảy ra',
        footer: '<a href="/charging">Mua vàng tại đây</a>. để có thêm kim cương'
      })
      setLoading(false)
    })
  }

  function rotate(position: number) {
    const wheel = document.getElementById('wheel-img');
    const newTransform = ((Math.round(transform / 360) * 360)) + (360 / 8 * position + 360) + 2880 //
    setTransform(newTransform);
    if (wheel) {
      wheel.style.transform = "rotate(" + newTransform + "deg)";
    }
  }

  return (
    <>
      <h2 className="text-center pt-5">VÒNG QUAY MAY MẮN</h2>
      <Alert variant="info" className="noti-info">
        <b>Vòng Quay May Mắn</b><br />
        <span>Tỷ lệ trúng 100%, <strong>QUAY LÀ TRÚNG</strong></span><br />
        <span>Bạn có thể kiểm kim cương bằng cách <Link to="/payment"><b>mua vàng tại đây</b></Link></span><br />
        <span className="text-danger font-weight-bold"><FontAwesomeIcon icon={faGem} size="sm" /> 10 viên kim cương</span> sẽ đổi được <span className="text-danger font-weight-bold">1 lượt quay </span><br />
        <p><span className="text-danger font-weight-bold">Đang có: <FontAwesomeIcon icon={faGem} size="sm" /> {currentUser?.diamond} viên kim cương</span></p>
      </Alert>
      <div className="wheel-content">
        <img src={toAbsoluteUrl('/images/wheel/1.png')} className="img-responsive rounded-circle wheel-image" ref={wheelRef} id="wheel-img" />
        <div className="wheel-button" onClick={() => startWheel()}>
          <img src={toAbsoluteUrl('/images/wheel/button.png')} className="wheel-button-image" />
        </div>
      </div>
      <div className="text-center pb-5">
        <p className="font-weight-bold text-danger mt-4">Bạn có {(currentUser?.diamond ?? 0) / 10} lượt quay</p>
      </div>
      <WheelHistory type={1}></WheelHistory>
    </>
  )
}
