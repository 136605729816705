import { useAppDispatch, useAppSelector } from 'app/hooks';
import React, { useEffect, useState } from 'react';
import { SideBar } from "../auth/components/SideBar";
import { fetchChargingHistory, fetchChargingRate, storeCharging } from './redux/paymentApi';
import { ListTelco } from 'app/modules/payment/models/Telco'
import * as Yup from "yup";
import { Alert, Form } from 'react-bootstrap';
import { Field, Formik } from 'formik';
import { currency } from 'app/helpers';
import { Input } from 'app/components/partials/form/Input';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { paymentActions } from './redux/paymentSlice';
import Pagination from 'react-js-pagination';
import { VipPopup } from './components/VipPopup';

export function Charging() {
  const [vipPopup, setVipPopup] = useState(true)
  const dispatch = useAppDispatch()
  const { chargingRate, payment } = useAppSelector(({ payment }) => ({ chargingRate: payment.chargingRate, payment: payment }))
  const { currentUser } = useAppSelector(({ auth }) => auth)
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState(null)
  const spinner = (<FontAwesomeIcon icon={faSpinner} size="lg" spin />);

  const handleSubmit = (values: any, resetForm: any) => {
    setLoading(true)
    storeCharging(values).then((data: any) => {
      setStatus(data.message ?? 'Có lỗi xảy ra')
      resetForm();
    }).catch((error) => {
      setStatus(error.message ?? 'Có lỗi xảy ra')
    }).finally(() => {
      setLoading(false)
      reloadHistory(payment.chargings.current_page)
    })
  }

  useEffect(() => {
    fetchChargingRate().then((data: any) => {
      dispatch(paymentActions.setChargingRate(data))
    }).finally(() => {
      setLoading(false)
    })
    reloadHistory(payment.chargings.current_page)
  }, [])

  function reloadHistory(page: number) {
    dispatch(paymentActions.setLoading(true))
    fetchChargingHistory(page).then((data) => {
      dispatch(paymentActions.setChargingHistory(data))
    }).finally(() => {
      dispatch(paymentActions.setLoading(false))
    })
  }

  const initialValues = {
    telco: "Viettel",
    amount: "",
    code: '',
    serial: ''
  };

  const Schema = Yup.object().shape({
    code: Yup.string().required('Không được bỏ trống').min(9, 'Thẻ cào có tối thiểu 9 ký tự'),
    serial: Yup.string().required('Không được bỏ trống').min(9, 'Mã serial có tối thiểu 9 ký tự')
  });

  return (
    <>
      <VipPopup display={vipPopup} setDisplay={setVipPopup}></VipPopup>
      <div className="row py-5">
        <SideBar />
        <div className="col-lg-9">
          <h4 className="title-text">Nạp thẻ cào</h4>
          <hr className="title-line" />

          <Alert variant="info">
            <span className="text-danger font-weight-bold">Tỉ lệ nạp thẻ {chargingRate[currentUser?.server_id ?? 1] ?? 0}%</span>
            <p>
              Nạp <b>100k</b> được <b>{currency((chargingRate[currentUser?.server_id ?? 1] ?? 0) * 100000)}</b><br />
              Cứ <b>100k</b> bạn sẽ nhận được <b>10 kim cương với</b> tương đương <b>1 lượt quay</b><br />
              Kim cương <strong>đổi lượt quay</strong> vàng <Link to="/wheel" style={{ fontSize: '16px', color: 'rgb(5, 67, 191)' }}><b>tại đây</b></Link><br />

            </p>
          </Alert>

          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={Schema}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values, resetForm)
            }}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <div className="form-group row">
                  <div className="col-lg-1"></div>
                  <label className="col-form-label col-lg-2">Tài khoản: </label>
                  <div className="col-lg-8">
                    <input className="form-control" type="text" defaultValue={`${currentUser?.name} - Vũ Trụ ${currentUser?.server_id}`} readOnly />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-1"></div>
                  <label className="col-form-label col-lg-2">Loại thẻ: </label>
                  <div className="col-lg-8">
                    <Field as="select" name="telco" className="form-control">
                      {ListTelco.map((item, key) => (
                        <option key={key} value={item.name}>{item.name}</option>
                      ))}
                    </Field>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-1"></div>
                  <label className="col-form-label col-lg-2">Mệnh giá: </label>
                  <div className="col-lg-8">
                    <Field as="select" name="amount" className="form-control">
                      <option value="">Chọn mệnh giá</option>
                      {[10000, 20000, 30000, 50000, 100000, 200000, 500000, 1000000].map((item, key) => (
                        <option key={key} value={item}>{currency(item)}</option>
                      ))}
                    </Field>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-1"></div>
                  <label className="col-form-label col-lg-2">Mã thẻ: </label>
                  <div className="col-lg-8">
                    <Field type="text" name="code" component={Input} placeholder="Mã thẻ"></Field>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-1"></div>
                  <label className="col-form-label col-lg-2">Số Serial: </label>
                  <div className="col-lg-8">
                    <Field type="text" name="serial" component={Input} placeholder="Mã serial"></Field>
                    <small>Vui lòng kiễm tra kĩ mệnh giá thẻ nạp</small>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3"></div>
                  <div className="col-lg-8">
                    {status && (
                      <Alert variant="danger">{status}</Alert>
                    )}
                    <button type="submit" className="btn btn-info form-control font-weight-bold" disabled={loading}>
                      <FontAwesomeIcon icon={loading ? faSpinner : faPaperPlane} spin={loading}></FontAwesomeIcon>{" "}
                      NẠP NGAY
                    </button>
                  </div>
                  <div className="col-lg-1"></div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className="row">
        <div className="table-responsive">
          <table className="table table-bordered table-hover">
            <thead className="bg-info text-white">
              <tr>
                <th>Thời gian</th>
                <th>Nhà mạng</th>
                <th>Mã thẻ</th>
                <th>Mệnh giá</th>
                <th>Kết quả</th>
                <th>Thực nhận</th>
              </tr>
            </thead>
            <tbody>
              {payment.chargings.data.length === 0 && !payment.loading ? (<tr className="text-center"><td colSpan={6}>Không có dữ liệu</td></tr>) : null}
              {payment.loading ? (<tr className="text-center"><td colSpan={6}>Đang tải...</td></tr>) :
                payment.chargings.data.map((history, key) => (
                  <tr key={key}>
                    <td>{history.created_at}</td>
                    <td>{history.telco}</td>
                    <td>
                      <span className="nowrap"><b>MT:</b> {history.code}</span>
                      <br />
                      <span className="nowrap"><b>SR: </b> {history.serial}</span>
                    </td>
                    <td>{currency(history.declared_value)}</td>
                    <td>
                      {history.status === 0 && spinner}
                      {history.status === 1 && <b className='text-success'>{currency(history.real_value)}</b>}
                      {history.status === 2 && <b className='text-danger'>{currency(history.real_value)}</b>}
                      {history.status === 3 && <b className='text-danger'>Thẻ sai</b>}
                      {history.status === 4 && <b className='text-secondary'>Bảo trì</b>}
                    </td>
                    <td>
                      {history.status === 1 ? currency(history.amount) : 0}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <Pagination
            activePage={payment.chargings.current_page}
            itemsCountPerPage={payment.chargings.per_page}
            totalItemsCount={payment.chargings.total}
            pageRangeDisplayed={5}
            onChange={(page: number) => reloadHistory(page)}
            itemClass="page-item"
            linkClass="page-link"
          />
        </div>
      </div>
    </>
  )
}
