import LuckyMoneyCommand from 'app/commands/LuckyMoneyCommand';
import { toAbsoluteUrl } from 'app/components/core';
import { useSocket } from 'app/components/core/Socket';
import React, { useEffect, useState } from 'react';
import { ILuckyMoney } from '../models/ILuckyMoney'
import { Envelope } from './Envelope';

export function LuckyMoney() {
  const { socket } = useSocket()
  const [envelopes, setEnvelopes] = useState<ILuckyMoney[]>([])
  const [amount, setAmount] = useState(0)

  useEffect(() => {
    socket.emit(LuckyMoneyCommand.LUCKY_MONEY_REFRESH)

    socket.on(LuckyMoneyCommand.LUCKY_MONEY_REFRESH, (data) => {
      setEnvelopes(data)
    })

    socket.on(LuckyMoneyCommand.LUCKY_MONEY_OPEN, (data) => {
      setAmount(data.amount)
    })

    socket.on(LuckyMoneyCommand.LUCKY_MONEY_USER_OPEN, (data) => {
      setAmount(data.amount)
    })

    return () => {
      socket.off(LuckyMoneyCommand.LUCKY_MONEY_OPEN)
      socket.off(LuckyMoneyCommand.LUCKY_MONEY_REFRESH)
      socket.off(LuckyMoneyCommand.LUCKY_MONEY_USER_OPEN)
    }

  }, [socket])

  function openEnvelope(id: number) {
    socket.emit(LuckyMoneyCommand.LUCKY_MONEY_OPEN, { id: id })
  }

  return (
    <>
      {envelopes.length > 0 && (
        <div className="row justify-content-center">
          {envelopes.map((envelope, key) => (
            <div key={key} className="lucky-money" onClick={() => openEnvelope(envelope.id)}>
              <img src={toAbsoluteUrl(`/images/lucky/lucky-money${envelope.status === 1 ? '-open' : ''}.png`)} />
            </div>
          ))}
        </div>
      )}
      {envelopes.length > 0 && <hr className="mb-1" />}
      <Envelope amount={amount} setAmount={setAmount}></Envelope>
    </>
  )
}
