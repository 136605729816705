import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface JarOfGoldState {
  amount: number;
  histories: any[];
}

const INITIAL_STATE: JarOfGoldState = {
  amount: 0,
  histories: []
};

const jarOfGoldSlice = createSlice({
  name: "jarOfGold",
  initialState: INITIAL_STATE,
  reducers: {
    setAmount(state, action: PayloadAction<any>) {
      try {
        state.amount = action.payload;
      } catch (error) {
        console.error(error);
      }
    },
    setHistory(state, action: PayloadAction<any>) {
      state.histories = action.payload.slice().reverse()
    },
  },
});

export const jarOfGoldActions = jarOfGoldSlice.actions;

export const jarOfGoldReducer = jarOfGoldSlice.reducer;

