
import { useAppSelector } from 'app/hooks';
import React from 'react';
import { ChatDicePublic } from '../dice/components/ChatDicePublic';
import { DiceGuideCard } from '../dice/components/DiceGuide';
import { DiceHistoryCard } from '../dice/components/DiceHistory';
import PKGameBot from '../pkGame/components/PKGameBot';
import { PKGameForm } from '../pkGame/components/PKGameForm';
import PKGameGuide from '../pkGame/components/PKGameGuide';
import { XSMBGameForm } from '../xsmb/components/XSMBGameForm';
import { XSMBResultCard } from '../xsmb/components/XSMBResult';
import { ChatPublic } from './components/ChatPublic';
import { GameForm } from './components/GameForm';
import { TurnInformation } from './components/TurnInformation';

export function LuckyNumber() {
  const { currentServer } = useAppSelector(({ lucky }) => ({
    currentServer: lucky.currentServer
  }))

  return (
    <div className="row">
      <div className="col-lg-6 card-closer mt-2">
        {currentServer <= 24 && (
          <>
            <TurnInformation />
            <GameForm />
          </>
        )}
        {(currentServer === 25 || currentServer === 26) && (
          <>
            {currentServer === 25 ? <PKGameForm/> : <PKGameGuide/>}
            <PKGameBot type={currentServer === 25 ? 1 : 2}/>
          </>
        )}
        {(currentServer === 27) && (
          <>
            <DiceGuideCard/>
            <DiceHistoryCard/>
          </>
        )}
        {(currentServer === 29) && (
          <>
          <XSMBResultCard/>
          <XSMBGameForm/>
          </>
        )}

      </div>
      <div className="col-lg-6 card-closer mt-2">
        {(currentServer <= 26 || currentServer === 29) && <ChatPublic />}
        {currentServer === 27 && <ChatDicePublic />}
      </div>
    </div>
  )
}
