export default {
  ONLINE_COUNTER: 'ONLINE_COUNTER',
  USER_ONLINE: 'USER_ONLINE',
  USER_ALERT: 'USER_ALERT',
  USER_NOTICE: 'USER_NOTICE',
  USER_BALANCE: 'USER_BALANCE',
  USER_STREAK: 'USER_STREAK',
  REFRESH_PERSONAL_RANKING: 'REFRESH_PERSONAL_RANKING',
  REFRESH_PAYMENT_RANKING: 'REFRESH_PAYMENT_RANKING',
  REFRESH_GUILD_RANKING: 'REFRESH_GUILD_RANKING',
}