import React, { useEffect, useState } from 'react';
import { faGamepad, faCoins, faGavel, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { currency } from 'app/helpers';
import { Alert, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GameButtonType } from 'app/modules/luckyNumber/components/GameButtonType'
import CurrencyInput from 'react-currency-input-field';
import { useLayout } from 'app/components/layout';
import { XSMBGameChooseNumberModal } from './XSMBChooseNumber';
import { xsmbActions } from '../redux/xsmbSlice';
import { useSocket } from 'app/components/core/Socket';
import XSMBCommand from 'app/commands/XSMBCommand';

export function XSMBGameForm() {
  const { socket } = useSocket()
  const dispatch = useAppDispatch()
  const { response, alertNotice } = useLayout()
  const [chooseNumber, setChooseNumber] = useState(false)
  const [currentGame, setCurrentGame] = useState('1')
  const [currentType, setCurrentType] = useState(1)
  const [amount, setAmount] = useState('')
  const { isAuthorized, currentUser, selectedNumbers } = useAppSelector(({ auth, xsmb }) => ({
    isAuthorized: auth.currentUser !== undefined,
    currentUser: auth.currentUser,
    selectedNumbers: xsmb.selectedNumbers
  }))

  function handlePlay(e: any) {
    e.preventDefault()
    if (amount === '' || parseInt(amount) < 1000000) {
      return alertNotice('Số tiền đặt cược tối thiểu là 1 triệu vàng')
    }
    
    socket.emit(XSMBCommand.XSMB_USER_PLAY, { type: currentGame, gate: currentType, numbers: selectedNumbers, amount: amount })
  }

  function removeNumber(value: number){
    dispatch(xsmbActions.setNumbers(selectedNumbers.filter(item => item !== value)))
  }

  return (
    <div className="card border-info mt-3">
      <div className="card-header bg-info text-light border-seccond text-center font-weight-bold">
        <FontAwesomeIcon icon={faGavel} /> ĐẶT CƯỢC
      </div>
      <div className="card-body border-seccond bg-light text-dark" style={{ padding: "30px 15px" }}>
        <div className="input-group form-group">
          <span className="input-group-prepend">
            <div className="input-group-text bg-light border-right-0 text-warning">
              <FontAwesomeIcon icon={faCoins} />
            </div>
          </span>
          <div className="form-control py-2 border-left-0 border readonly">{currency(currentUser?.balance ?? 0)}</div>
        </div>
        <form onSubmit={handlePlay}>
          <div className="input-group form-group">
            <span className="input-group-prepend">
              <div className="input-group-text bg-light border-right-1" style={{ color: 'red', padding: "6px 10px" }}>
                <FontAwesomeIcon icon={faGamepad} />
              </div>
            </span>
            <select className="form-control py-2 border-left-0 border text-danger" name="type" onChange={(e) => setCurrentGame(e.target.value)}>
              <option value="1">Mega chọn số (10tr ăn 33tr)</option>
              <option value="2">Chọn số đặc biệt (10tr ăn 800tr)</option>
              <option value="3">Xiên 2 (10tr ăn 100tr)</option>
              <option value="4">Xiên 3 (10tr ăn 400tr)</option>
              {/* <option value="5">Đỏ Đen - Tài xỉu (10tr ăn 19tr)</option>
              <option value="6">Xiên CLTX (10tr ăn 32tr)</option> */}
            </select>
          </div>

          <label>Kết quả dự đoán: </label>
          { (currentGame < '5') ? (
            <>
              <div className="form-group">
                <div className="circle-number-selection">
                    {selectedNumbers.map((i) =>
                      <span key={i} className={`circle-number small-circle`} onClick={() => removeNumber(i)}>{i < 10 ? `0${i}` : i}</span>
                    )}
                </div>
                <button type="button" name="selection" className="btn btn-sm btn-info" onClick={() => setChooseNumber(true)}>
                    <i className="fa fa-ticket" />&nbsp;&nbsp;Chọn số
                </button>
              </div>
              <div className="input-group form-group">
                <span className="input-group-prepend" style={{ borderRight: '1px solid red' }}>
                  <div className="input-group-text bg-light border-right-1">
                    <FontAwesomeIcon icon={faGavel} />
                  </div>
                </span>
                <CurrencyInput value={amount} allowNegativeValue={false} type="search" className="form-control py-2 border-left-0 border font-weight-bold amount-play" placeholder="Nhập tiền đặt cược cho mỗi số" onValueChange={(value) => setAmount(value ?? '')} />

              </div>
            </>
          ) : (<div className="form-group">
              <div className="btn-group" style={{ width: "100%" }}>
                <div className="row" style={{ width: "100%" }}>
                  {currentGame === '5' && (
                    <>
                      <GameButtonType name="Đỏ" variant="info" onClick={() => setCurrentType(1)} show={currentType === 1} />
                      <GameButtonType name="Đen" variant="warning" onClick={() => setCurrentType(2)} show={currentType === 2} />
                      <GameButtonType name="TÀI" variant="success" onClick={() => setCurrentType(3)} show={currentType === 3} />
                      <GameButtonType name="XỈU" variant="danger" onClick={() => setCurrentType(4)} show={currentType === 4} />
                    </>
                  )}
                  {currentGame === '6' && (
                    <>
                      <GameButtonType name="Đỏ - TÀI" variant="info" onClick={() => setCurrentType(5)} show={currentType === 5} />
                      <GameButtonType name="Đỏ - XỈU" variant="warning" onClick={() => setCurrentType(6)} show={currentType === 6} />
                      <GameButtonType name="Đen - TÀI" variant="success" onClick={() => setCurrentType(7)} show={currentType === 7} />
                      <GameButtonType name="Đen - XỈU" variant="danger" onClick={() => setCurrentType(8)} show={currentType === 8} />
                    </>
                  )}
                </div>
              </div>
          </div>)}

          {response.message && <Alert variant={response.type}>{response.message}</Alert>}

          {!isAuthorized ? (
            <Link to="/sign-in" className="btn btn-danger font-weight-bold text-uppercase form-control">
              <FontAwesomeIcon icon={faSignInAlt} /> ĐĂNG NHẬP ĐỂ THỰC HIỆN
            </Link>
          ) :
            <Button type="submit" variant="info" className="font-weight-bold form-control">ĐẶT NGAY</Button>
          }
            <XSMBGameChooseNumberModal display={chooseNumber} setDisplay={setChooseNumber}/>
        </form>
      </div>
    </div>
  )
}
