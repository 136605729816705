import React from 'react';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';

interface Props {
  path: string;
  children: any;
  [key: string]: any;
}

export function MenuItem({ path, children, ...props }: Props) {
  return (
    <li className="item">
      <FontAwesomeIcon icon={faSquare} className="square" />
      <NavLink to={path} {...props}>{children}</NavLink>
    </li>
  )
}