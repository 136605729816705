import { MenuItem } from './MenuItem';
export function SideBar() {
  return (
    <div className="col-lg-3">
      <div className="userHeader">
        <div className="title-box">
          <h5 className="pl-3">MENU TÀI KHOẢN</h5>
        </div>
        <div>
          <ul className="menu-list">
            <MenuItem path="/profile">
              Thông tin tài khoản
            </MenuItem>
            {/*<MenuItem path="/charging">*/}
            {/*  Nạp thẻ cào*/}
            {/*</MenuItem>*/}
            {/*<MenuItem path="/wallet/1">*/}
            {/*  Nạp ví Momo*/}
            {/*</MenuItem>*/}
            {/*<MenuItem path="/wallet/2">*/}
            {/*  Nạp Thesieure*/}
            {/*</MenuItem>*/}
            {/*<MenuItem path="/wallet/3">*/}
            {/*  Nạp Vietcombank*/}
            {/*</MenuItem>*/}
            {/* <MenuItem path="/transfer">
              Chuyển vàng
            </MenuItem> */}
            <MenuItem path="/transactions">
              Lịch sử giao dịch
            </MenuItem>
            {/* <MenuItem path="/vip-member">
              Nhận vàng VIP
            </MenuItem>
            <MenuItem path="/wheel" className="text-danger font-weight-bold">
              Vòng quay may mắn
            </MenuItem> */}
          </ul>
        </div>
      </div>
    </div>
  )
}