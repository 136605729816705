export const BET_TYPES = [
  null,
  'Đỏ',
  'Đen',
  'Tài',
  'Xỉu',
  'Đỏ - Tài',
  'Đỏ - Xỉu',
  'Đen - Tài',
  'Đen - Xỉu',
  'Dự đoán',
]