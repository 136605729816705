import { faCoins } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { currency } from "app/helpers";
import { useAppSelector } from "app/hooks";
import React, { useEffect, useState } from "react";
import { Alert, Card, Col, Form, FormGroup, Row } from "react-bootstrap";
import { TradeGoldLeft, TradeGoldRight } from "./components/TradeGold";
import { TradeGoldBarLeft, TradeGoldBarRight } from "./components/TradeGoldBar";
import { History } from './components/History';
import { WithdrawLeft, WithdrawRight } from "./components/Withdraw";

export default function Payment(props: any) {
  const type = parseInt((new URLSearchParams(props.location.search)).get('type') ?? "1") ?? 1
  const { setting } = useAppSelector(({ home }) => ({ setting: home.setting }))
  const currentUser = useAppSelector(({ auth }) => auth.currentUser)
  const [currentType, setCurrentType] = useState(1)

  useEffect(() => {
    setCurrentType(type)
  }, [type])

  return (
    <>
      <h2 className="text-center mt-5">{currentType === 2 ? `RÚT` : `NẠP`} VÀNG</h2>
      <hr style={{ width: '15%', height: '1px' }} className="bg-danger mb-3"></hr>
      <Alert variant="info">
        <div dangerouslySetInnerHTML={{ __html: currentType === 2 ? setting.withdraw_content : setting.payment_content }}></div>
      </Alert>
      {/* <Row>
        <Col lg={5} className="pt-2">
          <Card className="border-info">
            <Card.Header className="bg-info text-light border-info text-center font-weight-bold">
              <FontAwesomeIcon icon={faCoins} /> {" "}
              Số dư: {currency(currentUser?.balance ?? 0)}
            </Card.Header>
            <Card.Body className="border-info">

              <Form.Group className="form-group" controlId="server">
                <Form.Label>Máy chủ</Form.Label>
                <Form.Control type="input" value={`Máy chủ ${currentUser?.server_id ?? 0} sao`} readOnly></Form.Control>
              </Form.Group>

              <FormGroup className="form-group" controlId="type">
                <Form.Label>Hình thức</Form.Label>
                <Form.Control as="select" onChange={(e) => setCurrentType(parseInt(e.target.value ?? '0'))}>
                  <option value="1" selected={currentType === 1}>Giao dịch vàng</option>
                  <option value="3" selected={currentType === 3}>Giao dịch thỏi vàng</option>
                  <option value="2" selected={currentType === 2}>Rút vàng</option>
                </Form.Control>
              </FormGroup>

              {currentType === 1 && <TradeGoldLeft />}
              {currentType === 2 && <WithdrawLeft />}
              {currentType === 3 && <TradeGoldBarLeft />}

            </Card.Body>
          </Card>
        </Col>
        <Col lg={7} className="pt-2">

          {currentType === 1 && <TradeGoldRight />}
          {currentType === 2 && <WithdrawRight />}
          {currentType === 3 && <TradeGoldBarRight />}

        </Col>
      </Row> */}
      <Row className="pt-5">
        <Col lg={12}>
          <h3>Lịch sử giao dịch</h3>
          <History type={currentType}></History>
        </Col>
      </Row>
    </>
  )
}
