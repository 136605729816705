import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { History } from "../../luckyNumber/models/History";

export interface xsmbState {
  timeLeft: number;
  totalPlayer: number;
	histories: History[];
  resultNumbers: number[];
  selectedNumbers: number[];
  miniResult: string[][];
}

const INITIAL_STATE: xsmbState = {
  timeLeft: 0,
  totalPlayer: 0,
  histories: [],
  resultNumbers: [],
  selectedNumbers: [],
  miniResult: [],
};

const xsmbSlice = createSlice({
  name: "xsmb",
  initialState: INITIAL_STATE,
  reducers: {
    setTurnInfo(state, action: PayloadAction<any>) {
      try {
        state.timeLeft = action.payload.time;
        state.totalPlayer = action.payload.totalPlayer
        state.resultNumbers = action.payload.results
      } catch (error) {
        console.error(error);
      }
    },
    setHistory(state, action: PayloadAction<any>) {
      state.histories = action.payload.slice().reverse()
    },
    setNumbers(state, action: PayloadAction<number[]>){
      state.selectedNumbers = action.payload
    },
    setMiniResult(state, action: PayloadAction<string[][]>){
      state.miniResult = action.payload
    },
  },
});

export const xsmbActions = xsmbSlice.actions;

export const xsmbReducer = xsmbSlice.reducer;

