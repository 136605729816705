import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface Props {
  name: string;
  variant: 'info' | 'danger' | 'warning' | 'info' | 'success' | 'dark';
  show: boolean;
  onClick: (event: any) => void;
}

export function GameButtonType({ variant, name, show, onClick }: Props) {
  return (
    <div className="col-6 mt-2">
      <button type="button" className={`btn btn-${variant} text-white form-control rounded font-weight-bold text-uppercase ${!show ? 'low-opacity' : ''}`} onClick={onClick}>
        {name} {show && <FontAwesomeIcon icon={faCheckCircle} />}
      </button>
    </div>
  )
}