import { faGavel } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAppSelector } from 'app/hooks'
import React from 'react'
import { Modal } from 'react-bootstrap'

interface DiceGuideProps {
  display: boolean
  setDisplay: (value: boolean) => void
}

export function DiceGuide() {
  const { setting } = useAppSelector(({ home }) => ({ setting: home.setting }))

  return (
    <div dangerouslySetInnerHTML={{ __html: setting.dice_guide_content }}/>
  )
}

export function DiceGuideCard() {
  return (
    <div className="card border-info ">
        <div className="card-header bg-info text-light border-seccond text-center font-weight-bold">
            <FontAwesomeIcon icon={faGavel} /> HƯỚNG DẪN CHƠI
        </div>
        <div className="card-body bg-light text-dark" id="cardBodyKetQua">
            <DiceGuide/>
        </div>
    </div>
  )
}

export function DiceGuideModal({ display, setDisplay }: DiceGuideProps) {
  return (
    <Modal show={display} onHide={() => setDisplay(false)}>
      <Modal.Header closeButton>
        <div className="col text-center">
          <span className="font-weight-bold">Hướng dẫn</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <DiceGuide/>
      </Modal.Body>
    </Modal >
  )
}