import axios from "axios";

export const SETTING_URL = `/setting`;
export const GIFT_CODE_STORE = `/gift-code/store`;
export const GIFT_CODE_HISTORY = `/gift-code/history`;

export function getSetting() {
	return axios.get(SETTING_URL);
}

export function giftCodeStore({ code }: any) {
	return axios.post(GIFT_CODE_STORE, { code: code })
}

export function fetchGiftCodeHistory({ page, personal }: any) {
	return axios.get(`${GIFT_CODE_HISTORY}?page=${page}&personal=${personal}`);
}