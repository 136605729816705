import { InitResponse, IResponse } from 'app/modules/home/models/Response'
import { initUserAction, IUserAction } from 'app/modules/luckyNumber/models/IUserAction'
import React, { createContext, useContext, useState, useEffect } from 'react'

export interface LayoutContextModel {
  darkMode: boolean;
  navMenu: boolean
  alertModal: Boolean
  alertContent: string
  signInPopup: boolean
  signUpPopup: boolean
  passwordPopup: boolean
  guildPopup: boolean
  guildNotice: boolean
  dicePopup: boolean
  diceLivePopup: boolean
  guildAlertContent: string
  guildAlertPopup: boolean
  response: IResponse
  actionUser: boolean
  userAction: IUserAction
  jarOfGoldDisplay: boolean
  setDarkMode: (show: boolean) => void
  setGuildAlertContent: (message: string) => void
  setGuildAlertPopup: (show: boolean) => void
  setNavMenu: (show: boolean) => void
  setAlertModal: (show: boolean) => void
  setAlertContent: (show: string) => void
  setSignInPopup: (show: boolean) => void
  setSignUpPopup: (show: boolean) => void
  setPasswordPopup: (show: boolean) => void
  setGuildPopup: (show: boolean) => void
  setGuildNotice: (show: boolean) => void
  enableSplashScreen: () => void
  disableSplashScreen: () => void
  alertNotice: (message: string, type?: string) => void
  setDicePopup: (show: boolean) => void
  setDiceLivePopup: (show: boolean) => void
  guildAlertNotice: (message: string) => void
  setResponse: (response: IResponse) => void
  setActionUser: (show: boolean) => void
  setJarOfGoldDisplay: (show: boolean) => void
  setUserAction: (user: IUserAction) => void
}

const LayoutContext = createContext<LayoutContextModel>({
  darkMode: false,
  navMenu: false,
  alertModal: false,
  alertContent: '',
  signInPopup: false,
  signUpPopup: false,
  passwordPopup: false,
  guildPopup: false,
  guildNotice: false,
  dicePopup: false,
  diceLivePopup: false,
  guildAlertContent: '',
  guildAlertPopup: false,
  actionUser: false,
  userAction: initUserAction,
  response: InitResponse,
  jarOfGoldDisplay: false,
  setDarkMode: (show: boolean) => { },
  setNavMenu: (show: boolean) => { },
  setAlertModal: (show: boolean) => { },
  setAlertContent: (show: string) => { },
  setSignInPopup: (show: boolean) => { },
  setSignUpPopup: (show: boolean) => { },
  setPasswordPopup: (show: boolean) => { },
  setGuildPopup: (show: boolean) => { },
  setGuildNotice: (show: boolean) => { },
  alertNotice: (message: string, type?: string) => { },
  enableSplashScreen: () => { },
  disableSplashScreen: () => { },
  setDicePopup: (boolean: boolean) => { },
  setDiceLivePopup: (boolean: boolean) => { },
  setGuildAlertContent: (message: string) => { },
  setGuildAlertPopup: (show: boolean) => { },
  guildAlertNotice: (message: string) => { },
  setResponse: (response: IResponse) => { },
  setActionUser: (show: boolean) => { },
  setJarOfGoldDisplay: (show: boolean) => { },
  setUserAction: (user: IUserAction | undefined) => { },
})

const enableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen')
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'flex')
  }
}

const disableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen')
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'none')
  }
}

const LayoutProvider: React.FC = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false)
  const [alertModal, setAlertModal] = useState(false)
  const [alertContent, setAlertContent] = useState('')
  const [guildAlertContent, setGuildAlertContent] = useState('')
  const [guildAlertPopup, setGuildAlertPopup] = useState(false)
  const [signInPopup, setSignInPopup] = useState(false)
  const [signUpPopup, setSignUpPopup] = useState(false)
  const [passwordPopup, setPasswordPopup] = useState(false)
  const [guildPopup, setGuildPopup] = useState(false)
  const [dicePopup, setDicePopup] = useState(false)
  const [diceLivePopup, setDiceLivePopup] = useState(false)
  const [guildNotice, setGuildNotice] = useState(true)
  const [navMenu, setNavMenu] = useState(false)
  const [actionUser, setActionUser] = useState(false)
  const [jarOfGoldDisplay, setJarOfGoldDisplay] = useState(false)
  const [response, setResponse] = useState<IResponse>(InitResponse)
  const [userAction, setUserAction] = useState<IUserAction>(initUserAction)

  function alertNotice(message: string, type: string = 'warning') {
    setResponse({
      type: type,
      message: message,
    })
  }

  function guildAlertNotice(message: string) {
    setGuildAlertPopup(true)
    setGuildAlertContent(message)
  }

  const value: LayoutContextModel = {
    darkMode,
    navMenu,
    signInPopup,
    signUpPopup,
    passwordPopup,
    alertModal,
    alertContent,
    guildPopup,
    guildNotice,
    dicePopup,
    diceLivePopup,
    guildAlertContent,
    guildAlertPopup,
    response,
    actionUser,
    userAction,
    jarOfGoldDisplay,
    setDarkMode,
    setNavMenu,
    setAlertContent,
    setSignInPopup,
    setSignUpPopup,
    setPasswordPopup,
    setAlertModal,
    setGuildPopup,
    setGuildNotice,
    enableSplashScreen,
    disableSplashScreen,
    alertNotice,
    setDicePopup,
    setDiceLivePopup,
    setGuildAlertContent,
    setGuildAlertPopup,
    guildAlertNotice,
    setResponse,
    setActionUser,
    setUserAction,
    setJarOfGoldDisplay
  }

  useEffect(() => {
    disableSplashScreen()
  }, [])

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
}

export { LayoutContext, LayoutProvider }

export function useLayout() {
  return useContext(LayoutContext)
}
